// eslint-disable-next-line import/named
import { SortableEvent } from 'sortablejs';
import {
  WidgetJSONData,
  addWidgetToLayout,
  addWidgetToLayoutFromDrawer,
  changeArrayIndex,
  // changeArrayIndex,
} from '@/components/WidgetMaker/WidgetDnD/widgetDnDUtils';
import { fetchStoreInfoBySubDomain, getDraftThemeComponents } from '@/redux/actions';
import { useDispatch } from 'react-redux';
import { IWidgetDndActions, IWidgetDndHelpers, IWidgetDndInitialState } from '../types';
import { useSSRSelector } from '@/redux/ssrStore';
import { ADMIN_WIDGETS } from 'src/constants/widgets';
import { getStoreSubscriptionConditions } from 'src/utils/storeSubscriptionConfigs';

export const DEFAULT_WIDGET_DND_CONTEXT_HELPERS: IWidgetDndHelpers = {
  toggleSidePanelState: () => {},
  handleWidgetPropChangeForBasicWidgets: () => {},
  handlePublish: () => {},
  handleWidgetPropChange: () => {},
  handleWidgetAddFromDrawerWithConfig: () => {},
  addWidget: () => {},
  handleWidgetAdd: () => {},
  addWidgetViaMainSection: () => {},
  handleWidgetSelect: () => {},
  handleDeleteIconClick: () => {},
  removeWidgetRecursive: () => {},
  removeWidget: () => {},
  handleDelete: () => {},
  handleUpDownMove: () => {},
  handleWidgetAddFromDrawer: () => {},
  handleReorderWidgetSidebar: () => {},
  redirectToWidgetOutline: () => {},
};

interface IWidgetHelpersActions {
  widgetContextState: IWidgetDndInitialState;
  widgetContextActions: IWidgetDndActions;
}

export const useWidgetContextHelpers = (
  argues: IWidgetHelpersActions
): IWidgetDndHelpers => {
  const reduxDispatch = useDispatch();
  const { storeInfo } = useSSRSelector((state) => ({
    storeInfo: state.storeReducer.store,
  }));
  const subscriptionStatus = getStoreSubscriptionConditions(storeInfo);

  const { widgetContextState, widgetContextActions } = argues;

  const {
    setActiveItem,
    setWidgets,
    setOpenPostPublishModal,
    setHasUnsavedChanges,
    setSidePanelState,
    setActiveWidget,
    setIndexToAddWidgetAtFromMainSection,
    setIsHeaderFooterPropertyActive,
    setWidgetIdToDelete,
    setWidgetDeleteModalOpen,
  } = widgetContextActions;

  const handleWidgetPropChangeForBasicWidgets = (
    values: Record<string, any>,
    widgetId?: string,
    flushAll?: boolean
  ) => {
    widgetId = widgetId || widgetContextState.activeWidgetId;
    const activeWidgetData = widgetContextState.widgets[widgetId];
    let newActiveWidgetProps: Record<string, any> = {};
    if (flushAll) {
      newActiveWidgetProps = { ...values };
    } else {
      newActiveWidgetProps = {
        ...activeWidgetData.props,
        ...values,
      };
    }
    const newWidgetsData: WidgetJSONData = {
      ...activeWidgetData,
      props: newActiveWidgetProps,
    };
    const newWidgets = { ...widgetContextState.widgets, [widgetId]: newWidgetsData };
    setWidgets(newWidgets);
    setHasUnsavedChanges(true);
  };

  const toggleSidePanelState = (type) => {
    const refreshedState = {
      showWidgetOutline: false,
      showAddWidgetDrawerSection: false,
      showActiveWidgetProperty: false,
      showStyleLayout: false,
    };

    switch (type) {
      case 'widgetOutline':
        refreshedState.showWidgetOutline = true;
        break;
      case 'widgetDrawerSection':
        refreshedState.showAddWidgetDrawerSection = true;
        break;
      case 'widgetProperty':
        refreshedState.showActiveWidgetProperty = true;
        break;
      case 'styleLayout':
        refreshedState.showStyleLayout = true;
    }

    setSidePanelState({ ...widgetContextState.sidePanelState, ...refreshedState });
  };

  const handlePublish = () => {
    toggleSidePanelState('widgetOutline');
    setOpenPostPublishModal(true);
    setActiveItem({ outline: true, styleTemplate: false });
    setHasUnsavedChanges(false);
    if (subscriptionStatus?.isOldSubscriptionActive)
      reduxDispatch(fetchStoreInfoBySubDomain(storeInfo?.domain));
    else reduxDispatch(getDraftThemeComponents(storeInfo?.store_id));
  };

  const handleWidgetPropChange = (values: Record<string, any>, widgetId?: string) => {
    widgetId = widgetId || widgetContextState.activeWidgetId;
    const activeWidgetData = widgetContextState.widgets[widgetId];
    const newActiveWidgetProps: Record<string, any> = {
      ...activeWidgetData?.props,
      ...values,
    };
    const newWidgetsData: WidgetJSONData = {
      ...activeWidgetData,
      props: newActiveWidgetProps,
    };
    const newWidgets = { ...widgetContextState.widgets, [widgetId]: newWidgetsData };
    setWidgets(newWidgets);
    setHasUnsavedChanges(true);
  };

  const addWidget = (addedWidgetsData: WidgetJSONData | WidgetJSONData[]) => {
    setWidgets((oldWidgets) => {
      const widgetsDataArr = Array.isArray(addedWidgetsData)
        ? addedWidgetsData
        : [addedWidgetsData];
      widgetsDataArr.forEach((widgetData) => {
        const newIndex =
          widgetData.insertIndex >= 0
            ? widgetData.insertIndex
            : oldWidgets[widgetData.parent]?.children.length;
        oldWidgets[widgetData.parent]?.children.splice(newIndex, 0, widgetData.id);
        oldWidgets[widgetData.id] = widgetData;
      });
      return { ...oldWidgets };
    });
    setHasUnsavedChanges(true);
  };

  const handleWidgetAddFromDrawerWithConfig = (e: any, widget: any, widgetProp: any) => {
    const addedWidgetJSON = addWidgetToLayoutFromDrawer(e, widget);
    addedWidgetJSON['props'] = { ...addedWidgetJSON['props'], ...widgetProp };
    addedWidgetJSON['insertIndex'] =
      widgetContextState.indexToAddWidgetAtFromMainSection !== null
        ? widgetContextState.indexToAddWidgetAtFromMainSection
        : widgetContextState.widgets['page-builder']?.children?.length;
    if (addedWidgetJSON) {
      addWidget(addedWidgetJSON);
      const activeWidget = Array.isArray(addedWidgetJSON)
        ? addedWidgetJSON[0]
        : addedWidgetJSON;
      setActiveWidget(activeWidget.id);
      setIndexToAddWidgetAtFromMainSection(null);
      toggleSidePanelState('widgetProperty');
    }
  };

  const handleWidgetAdd = (e: SortableEvent) => {
    const isFromWidgetList = e.from.classList.contains('widgetGroupList');
    if (!isFromWidgetList) {
      return;
    }
    const addedWidgetJSON = addWidgetToLayout(e, handleWidgetAdd);
    if (addedWidgetJSON) {
      addWidget(addedWidgetJSON);
      const activeWidget = Array.isArray(addedWidgetJSON)
        ? addedWidgetJSON[0]
        : addedWidgetJSON;
      setActiveWidget(activeWidget.id);
    }
  };

  const addWidgetViaMainSection = (
    indexToInsertAt: number,
    widgetData?: any,
    parentId?: any
  ) => {
    setIndexToAddWidgetAtFromMainSection(indexToInsertAt);
    toggleSidePanelState('widgetDrawerSection');
  };

  const handleWidgetSelect = (widget?: any) => {
    const widgetId = widget?.id;
    setActiveWidget(widgetId);
    setIsHeaderFooterPropertyActive({
      status: false,
      type: null,
    });
    // handleWidgetPropChange(
    //   {
    //     subWidget: '',
    //     subWidgetIndex: -1,
    //   },
    //   widgetId //  activeWidgetId
    // );
    toggleSidePanelState('widgetProperty');
  };

  const removeWidgetRecursive = (
    oldWidgets: Record<string, WidgetJSONData>,
    widgetId: string
  ) => {
    const widget = oldWidgets[widgetId];
    if (!widget) {
      return oldWidgets;
    }
    const parent = oldWidgets[oldWidgets[widgetId].parent];
    parent.children = parent.children.filter((child) => !!oldWidgets[child]);
    const indexInParent = parent.children.indexOf(widgetId);
    parent.children.splice(indexInParent, 1);
    parent.children = [...parent.children];
    delete oldWidgets[widgetId];
    widget.children.forEach((child) => removeWidgetRecursive(oldWidgets, child));

    return oldWidgets;
  };

  const removeWidget = (widgetId: string) => {
    setWidgets((oldWidgets) => {
      return { ...removeWidgetRecursive(oldWidgets, widgetId) };
    });
    setHasUnsavedChanges(true);
  };

  const handleDeleteIconClick = (widget?: any) => {
    const widgetId = widget?.id;
    setWidgetIdToDelete(widgetId);
    setWidgetDeleteModalOpen(true);
  };

  const handleDelete = (widgetId?: any) => {
    // const widgets = e.currentTarget.closest('[data-widget]') as HTMLDivElement;
    removeWidget(widgetId || widgetContextState.widgetIdToDelete);
    setWidgetDeleteModalOpen(false);
    setWidgetIdToDelete(null);
    if (widgetContextState.activeWidgetId === widgetContextState.widgetIdToDelete) {
      setActiveWidget('');
    }
    toggleSidePanelState('widgetOutline');
  };

  const handleUpDownMove = (widgetId: string, direction: string) => {
    setWidgets((oldWidgets) => {
      const widgetParentId = oldWidgets[widgetId].parent;
      const widgetParent = oldWidgets[widgetParentId];
      const oldIndex = widgetParent.children.indexOf(widgetId);
      const newIndex = direction === 'up' ? oldIndex - 1 : oldIndex + 1;
      const newChildren = changeArrayIndex(widgetParent.children, oldIndex, newIndex);
      widgetParent.children = newChildren;
      return { ...oldWidgets };
    });
  };

  const handleWidgetAddFromDrawer = (e: any, widget: any) => {
    const addedWidgetJSON = addWidgetToLayoutFromDrawer(e, widget);
    let insertIndex =
      widgetContextState.indexToAddWidgetAtFromMainSection !== null
        ? widgetContextState.indexToAddWidgetAtFromMainSection
        : widgetContextState.widgets['page-builder']?.children?.length;
    if (widget.name === ADMIN_WIDGETS.HERO_BANNER.name) {
      insertIndex = 0;
    }
    addedWidgetJSON['insertIndex'] = insertIndex;

    if (addedWidgetJSON) {
      addWidget(addedWidgetJSON);
      const activeWidget = Array.isArray(addedWidgetJSON)
        ? addedWidgetJSON[0]
        : addedWidgetJSON;
      setActiveWidget(activeWidget.id);
      toggleSidePanelState('widgetProperty');
    }
  };

  const handleReorderWidgetSidebar = (e: SortableEvent, parentId: string, fn: any) => {
    const { oldIndex, newIndex } = e;
    const parentWidgetId = parentId;
    setWidgets((oldWidgets) => {
      const parentWidget = oldWidgets[parentWidgetId];
      parentWidget.children = changeArrayIndex(parentWidget.children, oldIndex, newIndex);
      return { ...oldWidgets };
    });
    fn(widgetContextState.widgets);
    setHasUnsavedChanges(true);
  };

  const redirectToWidgetOutline = () => {
    toggleSidePanelState('widgetOutline');
    setActiveWidget('');
    setIsHeaderFooterPropertyActive({
      status: false,
      type: null,
    });
  };

  return {
    toggleSidePanelState,
    handleWidgetPropChangeForBasicWidgets,
    handlePublish,
    handleWidgetPropChange,
    handleWidgetAddFromDrawerWithConfig,
    addWidget,
    handleWidgetAdd,
    addWidgetViaMainSection,
    handleWidgetSelect,
    handleDeleteIconClick,
    removeWidgetRecursive,
    removeWidget,
    handleDelete,
    handleUpDownMove,
    handleWidgetAddFromDrawer,
    handleReorderWidgetSidebar,
    redirectToWidgetOutline,
  };
};
