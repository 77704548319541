import { RegisteredWidget, registerWidget } from '@dotpe/kui/styles/registerWidgetForDnD';
import { useEffect } from 'react';
import { getHeroBannerHeightCalculation } from '@/redux/actions';
import { useDispatch } from 'react-redux';
import { getThemeComponent } from '@/utils/themeImports';
import { RICH_IMAGE_TEXT_DEFAULT_WIDGET_OPTIONS } from './Widgets2.0/RichTextImage/utils';
import { ADMIN_WIDGETS } from 'src/constants/widgets';
import { RICH_TEXT_DEFAULT_WIDGET_OPTIONS } from './Widgets2.0/RichText/utils';
import {
  DEFAULT_CARD_FRAME_CONFIG,
  DEFAULT_CARD_FRAME_CONFIG_CAT,
  DEFAULT_LIST_ARRANGEMENT_PRESENTATION,
  DEFAULT_LIST_ARRANGEMENT_PRESENTATION_CAT,
} from '../WidgetMaker/WidgetDnD/WidgetProperties/ListPresentation/ListPresentationConfig';
import {
  DEFAULT_FEATURED_CATEGORY_ARRANGEMENT_PRESENTATION,
  DEFAULT_FEATURED_COLLECTION_ARRANGEMENT_PRESENTATION,
  DEFAULT_FEATURED_TAG_ARRANGEMENT_PRESENTATION,
} from '../WidgetMaker/WidgetDnD/WidgetProperties/FeaturedListPresentation/FeaturedListPresentationConfig';
import { cloneDeep } from 'lodash';
import {
  DEFAULT_MULTI_COLUMN_ICON_CARD,
  DEFAULT_MULTI_COLUMN_ICON_IMAGE,
  DEFAULT_MULTI_COLUMN_ICON_LAYOUT,
} from '../WidgetMaker/WidgetDnD/WidgetProperties/MultiColumn/MultiColumnConfig';
import {
  DEFAULT_LOGO_SLIDER_CARD,
  DEFAULT_LOGO_SLIDER_LAYOUT,
} from '../WidgetMaker/WidgetDnD/WidgetProperties/LogoSlider/LogoSliderConfig';
import { FOOTER_DATA } from '../WidgetMaker/WidgetDnD/WidgetProperties/FooterBuilder/NewFooterConfig';
import {
  DEFAULT_INSTA_CARD_FRAME_CONFIG,
  DEFAULT_INSTA_LIST_ARRANGEMENT_PRESENTATION,
} from '../WidgetMaker/WidgetDnD/WidgetProperties/InstagramEmbed/InstagramEmbedConfig';
import {
  DEFAULT_FEATURED_REEL_CARD_CONFIG,
  FEATURED_REELS_PRESENTATION_CONFIG,
} from '../WidgetMaker/WidgetDnD/WidgetProperties/FeaturedReels/FeaturedReelsConfig';
import {
  DEFAULT_BLOG_CARD,
  DEFAULT_BLOG_LAYOUT,
} from '../WidgetMaker/WidgetDnD/WidgetProperties/BlogBuilder/BlogConfig';
import {
  TESTIMONIALS_CARD_CONFIG,
  TESTIMONIALS_PRESENTATION_CONFIG,
} from '../WidgetMaker/WidgetDnD/WidgetProperties/TestimonialsBuilder/TestimonialsBuilderConfig';
import {
  DEFAULT_GALLERY_CARD_CONFIG,
  GALLERY_PRESENTATION_CONFIG,
} from '../WidgetMaker/WidgetDnD/WidgetProperties/GalleryBuilder/GalleryConfig';
import {
  NEW_ANNOUNCEMENT_BAR_PRESENTATION_CONFIG,
  NEW_ANNOUNCEMENT_CARD_CONFIG,
} from '../WidgetMaker/WidgetDnD/WidgetProperties/NewAnnouncementBar/Config';

/* eslint-disable react-hooks/rules-of-hooks */

const registerThemeWidget = (widgetData: Omit<RegisteredWidget, 'type' | 'group'>) => {
  registerWidget({
    icon: 'horizontal_rule',
    ...widgetData,
    type: 'theme',
    group: 'legacy',
  });
};

const isSerializable = (jsonObj: any) => {
  try {
    JSON.parse(JSON.stringify(jsonObj));
    return true;
  } catch (e) {
    return false;
  }
};
export const catchJSONParse = <R extends Record<string, any> = Record<string, any>>(
  jsonString: string
): R => {
  if (typeof jsonString === 'string') {
    let json = {} as R;
    try {
      json = JSON.parse(jsonString);
    } catch (e) {
      console.warn('Cannot parse config props', e);
      return {} as R;
    }
    return json;
  }
  if (isSerializable(jsonString)) {
    return jsonString as R;
  }
};

export const getLayoutProps = (props: Record<string, any>) => {
  const config = catchJSONParse(props.config) || props.defaultConfig;
  const layout = {
    ...props,
    ...catchJSONParse(props.layout),
    type: props.type,
    name: props.name,
    config,
  };
  return layout;
};

const getComponent = (props: Record<string, any>) => {
  const layoutProps = getLayoutProps(props);
  const component = getThemeComponent(props.type, props.name, layoutProps);
  if (component) {
    return component;
  }
  return null;
};

registerThemeWidget({
  name: 'Header Menu',
  component: (props) => getComponent({ type: 'Header', name: 'Header1', ...props }),
  options: {
    name: [
      'Header1',
      'Header2',
      'Header3',
      'Header4',
      'Header5',
      'Header6',
      'Header7',
      'Header8',
      'Header9',
      'Header10',
      'Header11',
      'Header12',
      'Header13',
      'Header14',
    ],
    config: 'json',
    'config.color_scheme.bg_color': {
      type: 'color',
      label: 'Background Color',
      placeholder: '',
      hint: 'RGB Background Color',
    },
    'config.name': '',
    'config.hidden_components.login_view': false,
    'config.hidden_components.whatsapp_view': false,
    'config.hidden_components.isShopDropDownEnable': false,
  },
  icon: 'menu',
});

registerThemeWidget({
  name: 'Offers',
  component: (props) => getComponent({ type: 'Offers', name: 'PromoList1', ...props }),
  options: {
    name: ['PromoList1', 'PromoList2', 'PromoList3', 'PromoList4', 'PromoList5'],
    config: 'json',
    layout: '',
  },
  icon: 'redeem',
});

registerThemeWidget({
  name: 'Footer',
  component: (props) => getComponent({ type: 'Footer', name: 'Footer', ...props }),
  options: {
    name: ['Footer', 'Footer1', 'Footer2', 'Footer3', 'Footer4'],
    config: 'json',
    layout: 'json',
    includeCollectionSet: false,
    noDescription: false,
  },
  icon: 'dock_to_bottom',
});

registerThemeWidget({
  name: 'Middle Body',
  component: (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(getHeroBannerHeightCalculation());
    }, []);
    return getComponent({ type: 'MiddleBody', name: 'MiddleBody1', ...props });
  },
  options: {
    name: [
      'MiddleBody1',
      'MiddleBody2',
      'MiddleBody3',
      'MiddleBody4',
      'MiddleBody5',
      'MiddleBody6',
    ],
    layout: '',
  },
  icon: 'pages',
});

registerThemeWidget({
  name: 'CategoryProductWidget',
  options: {
    name: ['CategoryWidgetHorizontalView1', 'CategoryWidgetGridView1'],
    layout: 'json',
    config: 'json',
  },
  component: (props) => {
    return getComponent({
      type: 'CategoryProductWidget',
      name: 'CategoryWidgetHorizontalView1',
      ...props,
    });
  },
  icon: 'category',
});

registerThemeWidget({
  name: 'CollectionCategoryProductwidget',
  options: {},
  component: (props) => {
    return getComponent({
      type: 'CollectionCategoryProductWidget',
      name: 'CollectionCategoryProductWidget1',
      ...props,
    });
  },
  icon: 'list',
});

registerThemeWidget({
  name: 'Announcement Bar',
  options: {},
  component: (props) => {
    return getThemeComponent('HelloBar', 'SubHeader', {
      config: props.widgets && { widgets: props.widgets },
    });
  },
  icon: 'campaign',
});

registerThemeWidget({
  name: 'SortAndFilter',
  options: {},
  component: (props) => {
    return getComponent({
      type: 'SortingAndFilterWidget',
      name: 'SortingAndFilterWidget1',
      ...props,
    });
  },
  icon: 'sort_by_alpha',
});

registerThemeWidget({
  name: 'Testimonial',
  options: {
    max_items: 3,
  },
  component: (props) => {
    return getComponent({
      type: 'AdditionalPageWidget',
      slug: 'testimonial',
      config: { max_items: props.max_items || 3 },
    });
  },
  icon: 'reviews',
});

registerThemeWidget({
  name: 'Blog',
  options: {
    max_items: 2,
  },
  component: (props) => {
    return getComponent({
      type: 'AdditionalPageWidget',
      slug: 'blog',
      config: { max_items: props.max_items || 2 },
    });
  },
  icon: 'newspaper',
});

registerThemeWidget({
  name: 'Additional Page',
  options: {
    slug: '',
    config: 'json',
  },
  component: (props) => {
    return getComponent({ type: 'AdditionalPageWidget', ...props });
  },
  icon: 'note_add',
});

registerThemeWidget({
  name: 'Carousel',
  options: {
    name: ['Carousel'],
    // config: 'json',
  },
  component: (props) => {
    return getComponent({ type: 'Carousel', ...props });
  },
  icon: 'note_add',
});

registerThemeWidget({
  name: ADMIN_WIDGETS.HERO_BANNER.name,
  options: {
    name: [ADMIN_WIDGETS.HERO_BANNER.type],
    // config: 'json',
  },
  component: (props) => {
    return getComponent({ type: ADMIN_WIDGETS.HERO_BANNER.type, ...props });
  },
  icon: 'pages',
});

registerThemeWidget({
  name: 'StaticWidget',
  options: {
    slug: '',
    config: 'json',
  },
  component: (props) => {
    return getComponent({ type: 'StaticWidget', ...props });
  },
  icon: 'note_add',
});

const DEFAULT_CATEGORY_CONFIG = {
  animation: 'pop',
  aspect_ratio: 100,
  card_cta_visibility: false,
  desktop: {
    grid: {
      cols: 4,
      rows: 2,
    },
    spacing: 24,
  },
  heading: 'Categories',
  mobile: {
    grid: {
      cols: 2,
      rows: 4,
    },
    spacing: 24,
  },
};
registerThemeWidget({
  name: 'Category Widget',
  options: {
    name: [
      'CategoryWidget1',
      'CategoryWidget2',
      'CategoryWidget3',
      'CategoryWidget4',
      'CategoryWidget5',
      'CategoryWidget6',
      'CategoryWidget7',
      'CategoryWidget8',
      'CategoryWidget9',
      'CategoryWidget10',
      'CategoryWidget11',
      'CategoryWidget12',
      'CategoryWidget13',
      'CategoryWidget14',
      'CategoryWidget15',
      'CategoryWidget16',
    ],
    config: 'json',
  },
  component: (props) => {
    return getComponent({
      type: 'CategoryWidget',
      ...props,
      defaultConfig: DEFAULT_CATEGORY_CONFIG,
    });
  },
  icon: 'category',
});

registerThemeWidget({
  name: 'Collection Widget',
  options: {
    name: ['CollectionWidget1', 'CollectionWidget2', 'CollectionWidget3'],
    config: 'json',
  },
  component: (props) => {
    return getComponent({
      type: 'CollectionWidget',
      name: 'CollectionWidget1',
      ...props,
      defaultConfig: DEFAULT_CATEGORY_CONFIG,
    });
  },
  icon: 'collections_bookmark',
});

// Brand Website Component Registration

registerThemeWidget({
  name: 'ModernHeroBanner',
  component: (props) =>
    getComponent({
      type: 'ModernHeroBanner',
      name: 'ModernHeroBanner1',
      ...props,
      defaultConfig: DEFAULT_CATEGORY_CONFIG,
    }),
  options: {
    name: [
      'ModernHeroBanner1',
      'ModernHeroBanner2',
      'ModernHeroBanner3',
      'ModernHeroBanner4',
      'ModernHeroBanner5',
    ],
    config: 'json',
    layout: '',
  },
  icon: 'pages',
});

registerThemeWidget({
  name: 'ModernMiddleBodyColumn',
  component: (props) =>
    getComponent({
      type: 'ModernMiddleBodyColumn',
      name: 'ModernMiddleBodyColumn1',
      ...props,
      defaultConfig: DEFAULT_CATEGORY_CONFIG,
    }),
  options: {
    name: [
      'ModernMiddleBodyColumn1',
      'ModernMiddleBodyColumn2',
      'ModernMiddleBodyColumn3',
      'ModernMiddleBodyColumn4',
      'ModernMiddleBodyColumn5',
      'ModernMiddleBodyColumn6',
    ],
    config: 'json',
    layout: '',
  },
  icon: 'pages',
});

registerThemeWidget({
  name: 'ModernMiddleBodyGallery',
  component: (props) =>
    getComponent({
      type: 'ModernMiddleBodyGallery',
      name: 'ModernMiddleBodyGallery1',
      ...props,
      defaultConfig: DEFAULT_CATEGORY_CONFIG,
    }),
  options: {
    name: [
      'ModernMiddleBodyGallery1',
      'ModernMiddleBodyGallery2',
      'ModernMiddleBodyGallery3',
      'ModernMiddleBodyGallery4',
      'ModernMiddleBodyGallery5',
      'ModernMiddleBodyGallery6',
      'ModernMiddleBodyGallery7',
      'ModernMiddleBodyGallery8',
    ],
    config: 'json',
    layout: '',
  },
  icon: 'pages',
});

registerThemeWidget({
  name: 'ModernMiddleBodyTabs',
  component: (props) =>
    getComponent({
      type: 'ModernMiddleBodyTabs',
      name: 'ModernMiddleBodyTab1',
      ...props,
      defaultConfig: DEFAULT_CATEGORY_CONFIG,
    }),
  options: {
    name: ['ModernMiddleBodyTab1', 'ModernMiddleBodyTab2', 'ModernMiddleBodyTab3'],
    config: 'json',
    layout: '',
  },
  icon: 'pages',
});

registerThemeWidget({
  name: 'ModernMiddleBodyMaps',
  component: (props) =>
    getComponent({
      type: 'ModernMiddleBodyMaps',
      name: 'ModernMiddleBodyMaps1',
      ...props,
      defaultConfig: DEFAULT_CATEGORY_CONFIG,
    }),
  options: {
    name: ['ModernMiddleBodyMap1', 'ModernMiddleBodyMap2', 'ModernMiddleBodyMap3'],
    config: 'json',
    layout: '',
  },
  icon: 'pages',
});

registerThemeWidget({
  name: ADMIN_WIDGETS.RICH_TEXT_WITH_IMAGE.name,
  options: {
    name: [ADMIN_WIDGETS.RICH_TEXT_WITH_IMAGE.name],
    defaultProps: RICH_IMAGE_TEXT_DEFAULT_WIDGET_OPTIONS,
  },
  component: (props) => {
    return getComponent({
      type: ADMIN_WIDGETS.RICH_TEXT_WITH_IMAGE.type,
      name: ADMIN_WIDGETS.RICH_TEXT_WITH_IMAGE.name,
      defaultConfig: RICH_IMAGE_TEXT_DEFAULT_WIDGET_OPTIONS,
      ...props,
    });
  },
  icon: 'list',
});

registerThemeWidget({
  name: ADMIN_WIDGETS.RICH_TEXT.name,
  options: {
    name: [ADMIN_WIDGETS.RICH_TEXT.name],
    defaultProps: RICH_TEXT_DEFAULT_WIDGET_OPTIONS,
  },
  component: (props) => {
    return getComponent({
      type: ADMIN_WIDGETS.RICH_TEXT.type,
      name: ADMIN_WIDGETS.RICH_TEXT.name,
      defaultConfig: RICH_TEXT_DEFAULT_WIDGET_OPTIONS,
      ...props,
    });
  },
  icon: 'list',
});

registerThemeWidget({
  name: ADMIN_WIDGETS.SLIDESHOW.name,
  options: {
    name: [ADMIN_WIDGETS.SLIDESHOW.name],
  },
  component: (props) => {
    return getComponent({ type: ADMIN_WIDGETS.SLIDESHOW.type, ...props });
  },
  icon: 'note_add',
});

registerThemeWidget({
  name: ADMIN_WIDGETS.COLLECTIONS.name,
  options: {
    name: [ADMIN_WIDGETS.COLLECTIONS.name],
    defaultProps: {
      listPresentation: DEFAULT_LIST_ARRANGEMENT_PRESENTATION,
      cardFrame: DEFAULT_CARD_FRAME_CONFIG,
    },
  },
  component: (props) => {
    return getComponent({
      type: ADMIN_WIDGETS.COLLECTIONS.type,
      defaultConfig: {
        listPresentation: DEFAULT_LIST_ARRANGEMENT_PRESENTATION,
        cardFrame: DEFAULT_CARD_FRAME_CONFIG,
      },
      ...props,
    });
  },
  icon: 'note_add',
});

registerThemeWidget({
  name: ADMIN_WIDGETS.CATEGORY.name,
  options: {
    name: [ADMIN_WIDGETS.CATEGORY.name],
    defaultProps: {
      listPresentation: cloneDeep(DEFAULT_LIST_ARRANGEMENT_PRESENTATION_CAT),
      cardFrame: cloneDeep(DEFAULT_CARD_FRAME_CONFIG_CAT),
    },
  },
  component: (props) => {
    return getComponent({
      type: ADMIN_WIDGETS.CATEGORY.type,
      defaultConfig: {
        listPresentation: cloneDeep(DEFAULT_LIST_ARRANGEMENT_PRESENTATION_CAT),
        cardFrame: cloneDeep(DEFAULT_CARD_FRAME_CONFIG_CAT),
      },
      ...props,
    });
  },
  icon: 'note_add',
});

registerThemeWidget({
  name: ADMIN_WIDGETS.MULTI_COLUMN_WITH_ICON.name,
  options: {
    name: [ADMIN_WIDGETS.MULTI_COLUMN_WITH_ICON.name],
    defaultProps: {
      listPresentation: DEFAULT_MULTI_COLUMN_ICON_LAYOUT,
      cardFrame: DEFAULT_MULTI_COLUMN_ICON_CARD,
    },
  },
  component: (props) => {
    return getComponent({
      type: ADMIN_WIDGETS.MULTI_COLUMN_WITH_ICON.type,
      defaultConfig: {
        listPresentation: DEFAULT_MULTI_COLUMN_ICON_LAYOUT,
        cardFrame: DEFAULT_MULTI_COLUMN_ICON_CARD,
      },
      ...props,
    });
  },
  icon: 'note_add',
});

registerThemeWidget({
  name: ADMIN_WIDGETS.MULTI_COLUMN_WITH_IMAGE.name,
  options: {
    name: [ADMIN_WIDGETS.MULTI_COLUMN_WITH_IMAGE.name],
    defaultProps: {
      listPresentation: {
        ...DEFAULT_MULTI_COLUMN_ICON_LAYOUT,
        type: 'image',
      },
      cardFrame: DEFAULT_MULTI_COLUMN_ICON_IMAGE,
    },
  },
  component: (props) => {
    return getComponent({
      type: ADMIN_WIDGETS.MULTI_COLUMN_WITH_IMAGE.type,
      defaultConfig: {
        listPresentation: {
          ...DEFAULT_MULTI_COLUMN_ICON_LAYOUT,
          type: 'image',
        },
        cardFrame: DEFAULT_MULTI_COLUMN_ICON_IMAGE,
      },
      ...props,
    });
  },
  icon: 'note_add',
});

registerThemeWidget({
  name: ADMIN_WIDGETS.FEATURED_COLLECTIONS.name,
  options: {
    name: [ADMIN_WIDGETS.FEATURED_COLLECTIONS.name],
    defaultProps: {
      listPresentation: DEFAULT_FEATURED_COLLECTION_ARRANGEMENT_PRESENTATION,
      cardFrame: DEFAULT_CARD_FRAME_CONFIG,
    },
  },
  component: (props) => {
    return getComponent({
      type: ADMIN_WIDGETS.FEATURED_COLLECTIONS.type,
      defaultConfig: {
        listPresentation: DEFAULT_FEATURED_COLLECTION_ARRANGEMENT_PRESENTATION,
        cardFrame: DEFAULT_CARD_FRAME_CONFIG,
      },
      ...props,
    });
  },
  icon: 'note_add',
});

registerThemeWidget({
  name: ADMIN_WIDGETS.FEATURED_CATEGORIES.name,
  options: {
    name: [ADMIN_WIDGETS.FEATURED_CATEGORIES.name],
    defaultProps: {
      listPresentation: DEFAULT_FEATURED_CATEGORY_ARRANGEMENT_PRESENTATION,
      cardFrame: DEFAULT_CARD_FRAME_CONFIG,
    },
  },
  component: (props) => {
    return getComponent({
      type: ADMIN_WIDGETS.FEATURED_CATEGORIES.type,
      defaultConfig: {
        listPresentation: DEFAULT_FEATURED_CATEGORY_ARRANGEMENT_PRESENTATION,
        cardFrame: DEFAULT_CARD_FRAME_CONFIG,
      },
      ...props,
    });
  },
  icon: 'note_add',
});

registerThemeWidget({
  name: ADMIN_WIDGETS.FEATURED_TAGS.name,
  options: {
    name: [ADMIN_WIDGETS.FEATURED_TAGS.name],
    defaultProps: {
      listPresentation: DEFAULT_FEATURED_TAG_ARRANGEMENT_PRESENTATION,
      cardFrame: DEFAULT_CARD_FRAME_CONFIG,
    },
  },
  component: (props) => {
    return getComponent({
      type: ADMIN_WIDGETS.FEATURED_TAGS.type,
      defaultConfig: {
        listPresentation: DEFAULT_FEATURED_TAG_ARRANGEMENT_PRESENTATION,
        cardFrame: DEFAULT_CARD_FRAME_CONFIG,
      },
      ...props,
    });
  },
  icon: 'note_add',
});

registerThemeWidget({
  name: ADMIN_WIDGETS.LOGO_SLIDER.name,
  options: {
    name: [ADMIN_WIDGETS.LOGO_SLIDER.name],
    defaultProps: {
      listPresentation: DEFAULT_LOGO_SLIDER_LAYOUT,
      cardFrame: DEFAULT_LOGO_SLIDER_CARD,
    },
  },
  component: (props) => {
    return getComponent({
      type: ADMIN_WIDGETS.LOGO_SLIDER.type,
      defaultConfig: {
        listPresentation: DEFAULT_LOGO_SLIDER_LAYOUT,
        cardFrame: DEFAULT_LOGO_SLIDER_CARD,
      },
      ...props,
    });
  },
  icon: 'note_add',
});

registerThemeWidget({
  name: ADMIN_WIDGETS.BLOG_WIDGET.name,
  options: {
    name: [ADMIN_WIDGETS.BLOG_WIDGET.name],
    defaultProps: {
      listPresentation: DEFAULT_BLOG_LAYOUT,
      cardFrame: DEFAULT_BLOG_CARD,
    },
  },
  component: (props) => {
    return getComponent({
      type: ADMIN_WIDGETS.BLOG_WIDGET.name,
      defaultConfig: {
        listPresentation: DEFAULT_BLOG_LAYOUT,
        cardFrame: DEFAULT_BLOG_CARD,
      },
      ...props,
    });
  },
  icon: 'note_add',
});

registerThemeWidget({
  name: ADMIN_WIDGETS.FOOTER.name,
  options: {
    name: [ADMIN_WIDGETS.FOOTER.name],
    defaultProps: FOOTER_DATA,
  },
  component: (props) => {
    return getComponent({
      type: ADMIN_WIDGETS.FOOTER.type,
      defaultConfig: FOOTER_DATA,
      ...props,
    });
  },
  icon: 'note_add',
});

registerThemeWidget({
  name: ADMIN_WIDGETS.FEATURED_REELS.name,
  options: {
    name: [ADMIN_WIDGETS.FEATURED_REELS.name],
    defaultProps: {
      listPresentation: FEATURED_REELS_PRESENTATION_CONFIG,
      cardFrame: DEFAULT_FEATURED_REEL_CARD_CONFIG,
    },
  },
  component: (props) => {
    return getComponent({
      type: ADMIN_WIDGETS.FEATURED_REELS.type,
      defaultConfig: {
        listPresentation: FEATURED_REELS_PRESENTATION_CONFIG,
        cardFrame: DEFAULT_FEATURED_REEL_CARD_CONFIG,
      },
      ...props,
    });
  },
  icon: 'note_add',
});

registerThemeWidget({
  name: ADMIN_WIDGETS.INSTAGRAM_EMBED.name,
  options: {
    name: [ADMIN_WIDGETS.INSTAGRAM_EMBED.name],
    defaultProps: {
      listPresentation: DEFAULT_INSTA_LIST_ARRANGEMENT_PRESENTATION,
      cardFrame: DEFAULT_INSTA_CARD_FRAME_CONFIG,
    },
  },
  component: (props) => {
    return getComponent({
      type: ADMIN_WIDGETS.INSTAGRAM_EMBED.type,
      defaultConfig: {
        listPresentation: DEFAULT_INSTA_LIST_ARRANGEMENT_PRESENTATION,
        cardFrame: DEFAULT_INSTA_CARD_FRAME_CONFIG,
      },
      ...props,
    });
  },
  icon: 'note_add',
});

registerThemeWidget({
  name: ADMIN_WIDGETS.TESTIMONIALS.name,
  options: {
    name: [ADMIN_WIDGETS.TESTIMONIALS.name],
    defaultProps: {
      listPresentation: TESTIMONIALS_PRESENTATION_CONFIG,
      cardFrame: TESTIMONIALS_CARD_CONFIG,
    },
  },
  component: (props) => {
    return getComponent({
      type: ADMIN_WIDGETS.TESTIMONIALS.type,
      defaultConfig: {
        listPresentation: TESTIMONIALS_PRESENTATION_CONFIG,
        cardFrame: TESTIMONIALS_CARD_CONFIG,
      },
      ...props,
    });
  },
  icon: 'note_add',
});

registerThemeWidget({
  name: ADMIN_WIDGETS.GALLERY.name,
  options: {
    name: [ADMIN_WIDGETS.GALLERY.name],
    defaultProps: {
      listPresentation: GALLERY_PRESENTATION_CONFIG,
      cardFrame: DEFAULT_GALLERY_CARD_CONFIG,
    },
  },
  component: (props) => {
    return getComponent({
      type: ADMIN_WIDGETS.GALLERY.type,
      defaultConfig: {
        listPresentation: GALLERY_PRESENTATION_CONFIG,
        cardFrame: DEFAULT_GALLERY_CARD_CONFIG,
      },
      ...props,
    });
  },
  icon: 'note_add',
});

registerThemeWidget({
  name: ADMIN_WIDGETS.NEW_ANNOUNCEMENT_BAR.type,
  options: {
    name: [ADMIN_WIDGETS.NEW_ANNOUNCEMENT_BAR.name],
    defaultProps: {
      listPresentation: NEW_ANNOUNCEMENT_BAR_PRESENTATION_CONFIG,
      cardFrame: NEW_ANNOUNCEMENT_CARD_CONFIG,
    },
  },
  component: (props) => {
    return getComponent({
      type: ADMIN_WIDGETS.NEW_ANNOUNCEMENT_BAR.type,
      defaultConfig: {
        listPresentation: NEW_ANNOUNCEMENT_BAR_PRESENTATION_CONFIG,
        cardFrame: NEW_ANNOUNCEMENT_CARD_CONFIG,
      },
      ...props,
    });
  },
  icon: 'note_add',
});

// registerThemeWidget({
//   name: 'Test Widget',
//   component: props => {
//     // eslint-disable-next-line no-unused-vars
//     const { children, ...restProps } = props;
//     return (
//       <>
//         Test Widget
//         <h4>Raw Props</h4>
//         <pre>{JSON.stringify(restProps, null, 2)}</pre>
//         <h4>Layout Props</h4>
//         <pre>{JSON.stringify(getLayoutProps(restProps), null, 2)}</pre>
//       </>
//     );
//   },
//   options: {
//     config: 'json',
//     layout: 'json',
//     wysiwyg: 'wysiwyg',
//     html: 'html',
//     isChecked: false,
//     testimonials: [
//       {
//         name: '',
//         title: '',
//         image: '',
//         content: 'wysiwyg',
//       },
//     ],
//   },
//   icon: 'block',
// });
