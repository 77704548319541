import config from 'src/config';

export const API_SECRET_KEY = '938923c72331801ab55687160a048d5b';

export const B2B_STORE_TYPE_CONSTANT = 3;

export const LAZYPAY_AUTH_MODAL_VIEWS = {
  OTP: 'otp',
  ELIGIBLE: 'eligible',
  NONELIGIBLE: 'noneligible',
};

export const INIT_PAYMENT_DEFAULT_PHONE = '9999999999';

export const PAYMENT_TYPE_CONSTANTS = {
  CREDIT_CARD: 'creditCard',
  DEBIT_CARD: 'debitCard',
  CARD: 'card',
  PAYTM: 'paytm',
  AMEX_CARD: 'amexCard',
  GOOGLE_PAY: 'googlepay',
  PHONEPE: 'phonepe',
  PAYTM_UPI: 'paytmupi',
  LAZYPAY: 'lazyPay',
  COD: 'cod',
  EMI: 'cemi',
  AIRTEL: 'airtel',
  DUPI: 'dupi',
  MOBIKWIK: 'mobikwik',
};

export const ACTIVE_PAYMENT_METHOD = {
  CASH: 'CASH',
  CREDIT_CARD: 'CREDITCARD',
  DEBIT_CARD: 'DEBITCARD',
  CARD: 'CARD',
  LAZYPAY: 'LAZYPAY',
  UPI: 'UPI',
  EMI: 'CEMI',
  AIRTEL: 'airtel',
  DUPI: 'dupi',
  MOBIKWIK: 'MOBIKWIK',
};

export const DELIVERY_CHARGE_TYPE = {
  NOT_VALID: 0,
  FREE: 1,
  FIXED: 2,
  CUSTOM: 3,
  PINCODE_BASED: 4,
};

export const COD_CHARGE_TYPE = {
  FREE: 0,
  FIXED: 1,
  PERCENTAGE: 2,
};

export const ORDER_TYPE = {
  B2B: 0,
  DELIVERY: 1,
  PICKUP: 2,
  SELF: 3,
  SELF_IMAGE: 4,
  SERVICE: 5,
};

export const ORDER_STATUS = {
  PAYMENT_PENDING: 10,
  ORDER_SEEN: 9,
  ORDER_PLACED: 1,
  BILL_SENT: 2,
  COMPLETED: 7,
  REJECTED: 3,
  CUSTOMER_CANCELLED: 12,
  CONFIRMED_COD: 13,
  PART_ORDER_RECIEVED: 11,
};

export const VIEWPORT_SIZES = {
  MOBILE: 766,
  DESKTOP: 767,
};

export const SORTING_OPTIONS = [
  {
    displayText: 'Alphabetically, A-Z',
    key: 'alphabet',
    sortType: 'ascending',
    id: 1,
  },
  {
    displayText: 'Alphabetically, Z-A',
    key: 'alphabet',
    sortType: 'descending',
    id: 2,
  },
  {
    displayText: 'Price, Low to High',
    key: 'price',
    sortType: 'ascending',
    id: 3,
  },
  {
    displayText: 'Price, High to Low',
    key: 'price',
    sortType: 'descending',
    id: 4,
  },
];

export const FILTER_OPTIONS = [
  {
    displayText: "Hide 'Out of Stock Items'",
    key: 'out_of_stock',
  },
];

export const LEAD_GENERATION_TYPES = {
  EMAIL: 1,
  PHONE: 2,
};

export const CARD_TYPE = {
  AMEX: 'AMEX',
  VISA: 'VISA',
  MASTERCARD: 'MASTERCARD',
  MAESTRO: 'MAESTRO',
};

export const ORDERS_DROPDOWN_LIST = {
  MY_ORDERS: 'orders',
  LOGOUT: 'logout',
  DEFAULT_PHONE: 'defaultPhone',
};

export const ADDRESS_VISIBILITY = {
  VISIBLE: 1,
  HIDDEN: 2,
};

export const PREPAID_POSTPAID_METHODS = {
  POSTPAID: 0,
  PREPAID: 1,
  BOTH: 2,
};

export const STORE_CUSTOMIZATION = {
  ORDER_BASED: 0,
  SERVICE_BASED_WITH_ADDRESS_DISABLED: 1,
  SERVICE_BASED_WITH_ADDRESS_ENABLED: 2,
  B2B: 3,
};

export const SUBSCRIPTION_FLAG = {
  OLD_USER: 0,
  PURCHASED: 1,
  NOT_PURCHASED: 2,
  EXPIRED: 3,
};

export const AUTH_TOKEN = 'auth_token';

export const MINIMUM_ORDER_AMOUNT = 1.5;

export const PREPAID_ORDER_INVALID_AMOUNT_ERROR_MESSAGE = `Prepaid Order cannot be placed for order amount less than ${MINIMUM_ORDER_AMOUNT}`;

export const PREPAID_BOOKING_INVALID_AMOUNT_ERROR_MESSAGE = `Prepaid Booking cannot be placed for booking amount less than ${MINIMUM_ORDER_AMOUNT}`;

export const TRUECALLER_SESSION_ERROR =
  'Your TrueCaller Session has expired! Please Try Again';

export const TRANSACTION_ID_CONSTANT = 'txnID';
export const PAYTM_DETAILS = 'paytmdetails';

export const DISPLAY_STATUS = {
  REJECTED: 'rejected',
  BILL_SENT: 'billSent',
};

export const URL_PARAMS_CONSTANTS = {
  UTM_SOURCE: 'utm_source',
};

/**
 * _next comes in url of certain stores of ctx as next_js creates a JSON api when client navigates
 * from client side and getServerSideProps needs to run.
 *
 * $OG_IMAGE = Open graph image. Request are being generated for such og:images on server side and
 * for such url $OG_IMAGE is picked up from request url and send as domain name.
 *
 * %E2%80%8B = is UTF-8 code for ZERO-WIDTH-SPACE Added by merchant.
 */
export const INVALID_DOMAIN_NAMES = ['_next', '$OG_IMAGE', '%E2%80%8B', '${ip}'];

export const REDIRECT_DS_ADS_LINK = {
  DESKTOP_VERTICAL: 'https://web.dotpe.in/?utm_source=ftds',
  DESKTOP_FOOTER: 'https://web.dotpe.in/?utm_source=ftds',
  MOBILE_FOOTER: 'https://web.dotpe.in/?utm_source=ftds',
  MOBILE_MIDDLEBODY: 'https://web.dotpe.in/?utm_source=ftds',
  COMMON_REDIRECTION_LINK: 'https://download.digitalshowroom.app/download-ftds',
};

/**
 * status of eact collection item
 * active -> active collection
 * hidden -> hidden collection
 * delete -> deleted collection
 */
export const COLLECTION_STATUS = {
  ACTIVE: 'A',
  HIDDEN: 'H',
  DELETE: 'D',
};

/**
 * default collection images for collection list
 * in CollectionHeaderDropdownWidget1 and
 * CollectionHeaderDropdownWidget2
 */
export const DEFAULT_COLLECTION_IMAGE = [
  'https://cdn.dotpe.in/longtail/store-logo/4972/ryck8BWF.jpeg',
  'https://cdn.dotpe.in/longtail/store-logo/4972/lkYmdc78.jpeg',
  'https://cdn.dotpe.in/longtail/store-logo/4972/5UvfBZ3g.jpeg',
  'https://cdn.dotpe.in/longtail/store-logo/4972/7a7ie65j.jpeg',
  'https://cdn.dotpe.in/longtail/store-logo/4972/fjNvy0bf.jpeg',
  'https://cdn.dotpe.in/longtail/store-logo/4972/jcX0cWIs.jpeg',
  'https://cdn.dotpe.in/longtail/store-logo/4972/OLxOhwHb.jpeg',
  'https://cdn.dotpe.in/longtail/store-logo/4972/G6G5g4Y1.jpeg',
  'https://cdn.dotpe.in/longtail/store-logo/4972/xm2AVp8N.jpeg',
  'https://cdn.dotpe.in/longtail/store-logo/4972/wp3ofnhh.jpeg',
];

export const PROD_NAME_REPLACE_REGEX = /[&\/\\\n,# +()$~%.'":*?<>{}]/g;

export const SHOP_DOMAIN_ROUTE = '/[domain]/shop';

export const PROMO_HEADING = {
  APPLY: 'Coupon/ Bank or Wallet Offer',
  APPLIED: 'Bank or wallet offer applied!',
};

export const OFFER_TYPE = {
  STORE: 'store_offer',
  PAYMENT: 'payment_offer',
};

export const OFFERS_PAGE_ACTIVATED_TAB = {
  STORE: 1,
  PAYMENT: 2,
};

export const CTA_TEXT_OFFERS_TAB = {
  APPLY: 'Apply',
  REMOVE: 'Remove',
};

export const DELIVERY_TYPE = {
  PICKUP: 2,
  DELIVERY: 1,
};

export const DEFAULT_ADDRESS_ID = 69;

export const SESSION_STORAGE_KEY_NAMES = {
  DISCLAIMER: 'disclaimerWidgetShown',
};

export const PRODUCT_NAME_REGEX = /[&\/\\#, +()$~%.'":*?<>{}\n]/g;

export const MAX_CART_ITEMS_LIMIT = 100;

export const TOO_MANY_ITEMS_ERROR = 'Too Many Items';

export const SHOP_ROUTE = '/shop';

export const GST_FLAG = {
  DISABLE: 0,
  ENABLE: 1,
  ENABLED_THEN_DISABLED: 2,
};

export const ORDER_GST_TYPE = {
  NO_GST: 0,
  GST_INCLUDED: 1,
  GST_EXCLUDED: 2,
};

export const ORDER_PAYMENT_TYPE = {
  PREPAID: 1,
  POSTPAID: 0,
  BOTH: 2,
};

export const MAX_MOBILE_WIDTH = 768;

export const IMAGE_CONFIG_CONSTANT = {
  HIGH_RES_IMAGE: 1,
  THUMBNAIL_IMAGE: 2,
};

export const CATEGORY_TYPE = {
  CATEGORY: 0,
  TAG: 1,
};

export const HELLO_BAR_HIDDEN_STATUS = 2;
// Constants to store the X and Y coordinates of item clicked
export const CLICKED_ITEM_X_OFFSET = 'CLICKED_ITEM_X_OFFSET';
export const CLICKED_ITEM_Y_OFFSET = 'CLICKED_ITEM_Y_OFFSET';

export const SORTING_TYPE = {
  CATEGORIES: 0,
  PRICE_ASC: 1,
  PRICE_DESC: 2,
  RATING: 3,
};

export const FILTER_COUNTER_OPTIONS = {
  INC: 1,
  DEC: -1,
};

export const DEFAULT_IMAGE_URL =
  'https://cdn.dotpe.in/longtail/store-items/default_image.png';

export const DS_LOGO_IMAGE_URL = 'https://cdn.dotpe.in/longtail/themes/17/5qja2OwN.png';

export const DEFAULT_IMAGE_OBJECT = {
  image_url: DEFAULT_IMAGE_URL,
  thumbnail_url: DEFAULT_IMAGE_URL,
};
export const CLICKED_ITEM_OBJ = 'CLICKED_ITEM_OBJ';

export const MEDIA_TYPES = {
  IMAGES: 1,
  VIDEO: 2,
};

export const VARIANT_OPTIONS = {
  COLOR: 'Colour',
};

export const COLOR_FILTER_OPTION = 'Colour';

export const ITEM_ACTIVE_STATUS = {
  DELETED: 0,
  ACTIVE: 1,
  HIDDEN: 2,
};

export const GENDER_TYPES = {
  MALE: 'Male',
  FEMALE: 'Female',
  OTHERS: 'Prefer not to say',
};

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const FEATURE_LOCKS_INITIAL_VALUE = {
  isVisible: false,
  isLocked: true,
};

export const FEATURE_LOCKS_INITIAL_LIST = {
  collections: FEATURE_LOCKS_INITIAL_VALUE,
  couponsVouchers: FEATURE_LOCKS_INITIAL_VALUE,
  crr: FEATURE_LOCKS_INITIAL_VALUE,
  fbPixel: FEATURE_LOCKS_INITIAL_VALUE,
  googleAnalytics: FEATURE_LOCKS_INITIAL_VALUE,
  gtm: FEATURE_LOCKS_INITIAL_VALUE,
  installApp: FEATURE_LOCKS_INITIAL_VALUE,
  leadGen: FEATURE_LOCKS_INITIAL_VALUE,
  visitorLogin: FEATURE_LOCKS_INITIAL_VALUE,
};

export const EMAIL_REGEX_ADDRESS = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const PARTIAL_PAYMENT_OPTION = {
  FULL_PAYMENT: 0,
  PARTIAL_PAYMENT: 1,
};

export const PARTIAL_PAYMENT_TYPES = {
  PERCENTAGE: 'percentage',
  FLAT_AMOUNT: 'flatAmount',
};

export const SOCIAL_BASE_URL = {
  whatsapp: 'https://api.whatsapp.com/send?',
  twitter: 'https://twitter.com/intent/tweet',
  telegram: 'https://t.me/share/url',
  facebook: 'https://www.facebook.com/sharer/sharer.php',
};

export const SortList = [
  { name: 'Categories', type: SORTING_TYPE.CATEGORIES },
  { name: 'Price: Low to High', type: SORTING_TYPE.PRICE_ASC },
  { name: 'Price: High to Low', type: SORTING_TYPE.PRICE_DESC },
  { name: 'Customer Rating', type: SORTING_TYPE.RATING },
];

export const FOOTER_STORE_POLICIES_PAGE_TYPE = {
  TNC: 1,
  POLICY: 3,
};

export const APP_INSTALL_PROMPT_USER_ACTIONS = {
  ACCEPTED: 'accepted',
  DISMISSED: 'dismissed',
};

export const ITEM_CLICKED_DATA = 'ITEM_CLICKED_DATA';
export const COLLECTION_CATALOG_ITEMS = 'collectionCatalogItems';
export const ITEM_CLICKED_DETAIL_EXPIRY_DURATION = 100000; // 1 minute
export const REMOVE_ITEM_DETAIL_TIMEPERIOD = 3000; // 3 seconds

export const PREPAID_FLAG = {
  POSTPAID: 0,
  PREPAID: 1,
};

/** Icon CDN's for cards */
export const cc_icons = {
  VISA: config.CDN_SERVER_URL + 'image/visa-icon.png',
  MASTERCARD: config.CDN_SERVER_URL + 'image/mastercard-icon.png',
  AMEX: config.CDN_SERVER_URL + 'image/american-express-icon.png',
  MAESTRO: config.CDN_SERVER_URL + 'image/maestro-icon.png',
  DebitCard: config.CDN_SERVER_URL + 'image/ic-credit-card.svg',
};
/**
 * When no filter is selected in sortAndFilter, then default count is 0.
 */
export const DEFAULT_FILTER_COUNT_VALUE = 0;

export const COLLECTION_CARD_ANIMATION_TYPE = {
  0: 'none',
  1: 'zoom-in',
  2: 'zoom-out',
};

export const CARD_SKELETON = 'https://i.imgur.com/ewkhcIA.gif';

export const DS_TERMS_AND_CONDITIONS_URL =
  'https://digitalshowroom.in/terms-and-conditions';

export const CANCELATION_AND_REFUND_POLICY_URL =
  'https://digitalshowroom.in/customer-cancellation-and-refund';

export const PREVIEW_PARAMS = {
  THEME_ID: 'tid',
  TAG_ID: 'tag_id',
  ITEMS_AVAILABLE: 'items_available',
  THEME_PAGE: 'theme_page',
};

export const MAX_VISIBLE_NAV_LINKS = 5;

export const btnReset =
  ' tw-p-0 tw-m-0 tw-border-none tw-w-auto tw-color-inherit tw-bg-inherit tw-cursor-pointer tw-leading-[1]';
