/* eslint-disable */
import { getDefaultConfig } from 'src/components/WidgetMaker/utils/common';
import { ADMIN_WIDGETS } from 'src/constants/widgets';

// Default data for layout and cards
export const DEFAULT_FEATURED_COLLECTION_ARRANGEMENT_PRESENTATION = {
  layoutName: 'layout1',
  viewLabel: 'Featured Collection',
  layoutType: 'carousel',
  heading: '<h2>Featured collection</h2>',
  headingCharCount: 0,
  singularSelection: true,
  description: '',
  descriptionCharCount: 0,
  descriptionPosition: 'below',
  textAlign: 'left',
  colors: {
    selectedColorSchemeIndex: 0,
    /**
     * if isEnabled then text
     */
    defaultOverrides: {
      colorSchemeName: 'globalDefault',
      colorSchemes: {
        background: '#000',
        text: '#ffff',
      },
      isEnabled: false,
    },
    colorSchemes: [
      {
        background: '#ffffff',
        text: '#000000',
        isDefault: true,
      },
      {
        background: '#000000',
        text: '#ffffff',
        isDefault: false,
      },
    ],
  },
  openInNewTab: false,
  contentItems: {
    isAllSelected: false,
    type: 'featuredCollection',
    listItem: [],
  },
  numColumnsDesktop: 4,
  numColumnsMobile: 2,
  progressIndicator: 'none',
  arrowShape: 'circular',
  arrowColor: '#000000',
  shapeColor: '#ffffff',
  arrowPosition: 'top',
  progressIndicatorColor: '#000000',
  showArrowInMobile: true,
  mobilePaddingY: 40,
  paddingY: 70,
  marginTop: 0,
  marginBottom: 0,
  viewAllItemsButtonConfig: [
    getDefaultConfig('bannerButton', '#111C36', '#FFFFFF', 'View all'),
  ],
};

export const DEFAULT_FEATURED_TAG_ARRANGEMENT_PRESENTATION = {
  layoutName: 'layout1',
  viewLabel: 'Featured Tag',
  layoutType: 'carousel',
  heading: '<h2>Featured tag</h2>',
  headingCharCount: 0,
  singularSelection: true,
  description: '',
  descriptionCharCount: 0,
  descriptionPosition: 'below',
  textAlign: 'left',
  colors: {
    selectedColorSchemeIndex: 0,
    /**
     * if isEnabled then text
     */
    defaultOverrides: {
      colorSchemeName: 'globalDefault',
      colorSchemes: {
        background: '#000',
        text: '#ffff',
      },
      isEnabled: false,
    },
    colorSchemes: [
      {
        background: '#ffffff',
        text: '#000000',
        isDefault: true,
      },
      {
        background: '#000000',
        text: '#ffffff',
        isDefault: false,
      },
    ],
  },
  openInNewTab: false,
  contentItems: {
    isAllSelected: false,
    type: 'featuredTag',
    listItem: [],
  },
  numColumnsDesktop: 4,
  numColumnsMobile: 2,
  progressIndicator: 'none',
  arrowShape: 'circular',
  arrowColor: '#000000',
  shapeColor: '#ffffff',
  arrowPosition: 'top',
  progressIndicatorColor: '#000000',
  showArrowInMobile: true,
  mobilePaddingY: 40,
  paddingY: 70,
  marginTop: 0,
  marginBottom: 0,
  viewAllItemsButtonConfig: [
    getDefaultConfig('bannerButton', '#111C36', '#FFFFFF', 'View all'),
  ],
};

export const DEFAULT_FEATURED_CATEGORY_ARRANGEMENT_PRESENTATION = {
  layoutName: 'layout1',
  viewLabel: 'Featured Category',
  layoutType: 'carousel',
  heading: '<h2>Featured category</h2>',
  headingCharCount: 0,
  singularSelection: true,
  description: '',
  descriptionCharCount: 0,
  descriptionPosition: 'below',
  textAlign: 'left',
  colors: {
    selectedColorSchemeIndex: 0,
    /**
     * if isEnabled then text
     */
    defaultOverrides: {
      colorSchemeName: 'globalDefault',
      colorSchemes: {
        background: '#000',
        text: '#ffff',
      },
      isEnabled: false,
    },
    colorSchemes: [
      {
        background: '#ffffff',
        text: '#000000',
        isDefault: true,
      },
      {
        background: '#000000',
        text: '#ffffff',
        isDefault: false,
      },
    ],
  },
  openInNewTab: false,
  contentItems: {
    isAllSelected: false,
    type: ADMIN_WIDGETS.FEATURED_CATEGORIES.type,
    listItem: [],
  },
  numColumnsDesktop: 4,
  numColumnsMobile: 2,
  progressIndicator: 'none',
  arrowShape: 'circular',
  arrowColor: '#000000',
  shapeColor: '#ffffff',
  arrowPosition: 'top',
  progressIndicatorColor: '#000000',
  showArrowInMobile: true,
  mobilePaddingY: 40,
  paddingY: 40,
  marginTop: 0,
  marginBottom: 0,
  viewAllItemsButtonConfig: [
    getDefaultConfig('bannerButton', '#111C36', '#FFFFFF', 'View all'),
  ],
};

export const DESCRIPTION_POSITION = [
  {
    text: 'Top',
    value: 'top',
  },
  {
    text: 'Below',
    value: 'below',
  },
];

export const DEFAULT_CARD_FRAME_CONFIG = {
  cardShape: 'portraitShort',
  aspectRatio: '3:4',
  hoverEffect: 'zoomIn',
  contentConfig: {
    textConfig: {
      type: 'below',
      xAlignment: 'center',
      yAlignment: 'below',
      textThicness: 'regular',
      textSize: 'medium',
      textCase: 'none',
      overlayOpacity: 1,
      appearanceButton: [getDefaultConfig('collectionBtn', '#111C36', '#111C36')],
    },
    buttonConfig: [
      {
        ...getDefaultConfig('bannerButton', '#FFFFFF', '#111C36', 'View products'),
        isShow: false,
      },
    ],
  },
};

export const CUSTOM_COLOR_OVERRIDES_LIST = [
  { label: 'Use site colour scheme', value: 'globalDefault', forDefault: true },
  { label: 'White', value: 'white', forDefault: false },
];

export const LIST_LAYOUT_SELECTABLES = [
  {
    name: 'layout1',
    cdn: 'https://cdn.dotpe.in/longtail/themes/8073865/eVb88qUO.webp',
    // These are the base configs meapped with each layout
    configs: {
      layout: {
        layoutName: 'layout1',
        layoutType: 'grid',
      },
      card: {
        contentConfig: {
          buttonConfig: [{ isShow: false }],
        },
      },
    },
  },
  {
    name: 'layout2',
    cdn: 'https://cdn.dotpe.in/longtail/themes/8073865/VIpuX8Me.webp',
    // These are the base configs mapped with each layout
    configs: {
      layout: {
        layoutName: 'layout2',
        layoutType: 'carousel',
      },
      card: {
        contentConfig: {
          buttonConfig: [{ isShow: false }],
        },
      },
    },
  },
  {
    name: 'layout3',
    cdn: 'https://cdn.dotpe.in/longtail/themes/8073865/PyKLoA0B.webp',
    // These are the base configs mapped with each layout
    configs: {
      layout: {
        layoutName: 'layout3',
        layoutType: 'grid',
      },
      card: {
        contentConfig: {
          textConfig: {
            appearanceButton: [
              {
                type: 'textual',
                buttonAnimationConfig: { classKey: '', animationName: 'None' },
              },
            ],
          },
          buttonConfig: [{ isShow: true }],
        },
      },
    },
  },
  {
    name: 'layout4',
    cdn: 'https://cdn.dotpe.in/longtail/themes/8073865/sy3HSJmO.webp',
    // These are the base configs mapped with each layout
    configs: {
      layout: {
        layoutName: 'layout4',
        layoutType: 'carousel',
      },
      card: {
        contentConfig: {
          textConfig: {
            appearanceButton: [
              {
                type: 'textual',
                buttonAnimationConfig: { classKey: '', animationName: 'None' },
              },
            ],
          },
          buttonConfig: [{ isShow: true }],
        },
      },
    },
  },
];

// Constant and some other data

const POSITIONS_DATA = [
  {
    text: 'Left',
    value: 'left',
    iconBaseClass: 'wb-sprite ic-align-left faded',
    iconActiveClass: 'wb-sprite ic-align-left',
    itemLevelClass: 'tw-w-[50%]',
  },
  {
    text: 'Centre',
    value: 'center',
    iconBaseClass: 'wb-sprite ic-align-center faded',
    iconActiveClass: 'wb-sprite ic-align-center',
    itemLevelClass: 'tw-w-[50%]',
  },
];

const LAYOUT_STYLE = [
  {
    text: 'Grid',
    value: 'grid',
    itemLevelClass: 'tw-w-[50%]',
  },
  {
    text: 'Carousel',
    value: 'carousel',
    itemLevelClass: 'tw-w-[50%]',
  },
];

const CARD_TEXT_ALIGNMENT = [
  {
    text: 'Left',
    value: 'left',
    iconBaseClass: 'wb-sprite ic-align-left faded',
    iconActiveClass: 'wb-sprite ic-align-left',
    itemLevelClass: 'tw-w-[33%]',
  },
  {
    text: 'Centre',
    value: 'center',
    iconBaseClass: 'wb-sprite ic-align-center faded',
    iconActiveClass: 'wb-sprite ic-align-center',
    itemLevelClass: 'tw-w-[33%]',
  },
  {
    text: 'Right',
    value: 'right',
    iconBaseClass: 'wb-sprite ic-align-right faded',
    iconActiveClass: 'wb-sprite ic-align-right',
    itemLevelClass: 'tw-w-[33%]',
  },
];

const getCardShare = (text, spiteDetails) => (
  <div className="tw-flex tw-items-center tw-gap-[10px]">
    <div className={`wb-sprite ${spiteDetails}`}></div>
    <div>{text}</div>
  </div>
);

export const ASPECT_RATIO_MAP = {
  square: '1:1',
  portraitTall: '2:3',
  portraitShort: '3:4',
  landscape: '3:2',
  landscapeWide: '4:3',
  circle: '1:1',
  blob: '1:1',
  arch: '3:4',
};

export const CENTER_ALIGN_CARD = ['circle', 'blob'];

export const ARCH = 'arch';

export const POSITION_DATA = {
  TOP: 'top',
  MIDDLE: 'middle',
  BOTTOM: 'bottom',
};

const ARROW_SHAPE_DROPDOWN = [
  {
    value: 'onlyArrow',
    renderData: () => getCardShare('Only arrow', 'ic-simple-arrow'),
  },
  {
    value: 'circular',
    renderData: () => getCardShare('Circular', 'ic-arrow-circle'),
  },
  {
    value: 'rounded',
    renderData: () => getCardShare('Rounded', 'ic-arrow-rounded'),
  },
  {
    value: 'square',
    renderData: () => getCardShare('Square', 'ic-arrow-square'),
  },
];

const PROGRESS_INDICATOR_DROPDOWN = [
  {
    text: 'None',
    value: 'none',
  },
  {
    text: 'Short progress bar',
    value: 'shortProgressBar',
  },
  {
    text: 'Full width bar',
    value: 'fullWidthBar',
  },
  {
    text: 'Dash and dots',
    value: 'dashAndDots',
  },
];

const TEXT_CASE = [
  {
    text: 'Default',
    value: 'none',
    itemLevelClass: 'tw-w-[33%]',
  },
  {
    text: 'Aa',
    value: 'capitalize',
    itemLevelClass: 'tw-w-[33%]',
  },
  {
    text: 'AA',
    value: 'uppercase',
    itemLevelClass: 'tw-w-[33%]',
  },
];

const TEXT_POSITION_DROPDOWN = [
  {
    text: 'On card - Top',
    value: 'top',
  },
  {
    text: 'On card - Middle',
    value: 'middle',
  },
  {
    text: 'On card - Bottom',
    value: 'bottom',
  },
  {
    text: 'Below',
    value: 'below',
  },
];

const ARROW_POSITION = [
  {
    text: 'Top',
    value: 'top',
  },
  {
    text: 'Center',
    value: 'center',
  },
  {
    text: 'bottom',
    value: 'bottom',
  },
];

const TEXT_SIZE_DROPDOWN = [
  {
    text: 'Extra small (12px)',
    value: 'extraSmall',
    itemLevelClass: { fontSize: '12px !important' },
  },
  {
    text: 'Small (14px)',
    value: 'small',
    itemLevelClass: { fontSize: '14px !important' },
  },
  {
    text: 'Medium (16px)',
    value: 'medium',
    itemLevelClass: { fontSize: '16px !important' },
  },
  {
    text: 'Large (18px)',
    value: 'large',
    itemLevelClass: { fontSize: '18px !important' },
  },
  {
    text: 'Extra Large (20px)',
    value: 'extraLarge',
    itemLevelClass: { fontSize: '20px !important' },
  },
];

const TEXT_THICKNESS = [
  {
    text: 'Thin',
    value: 'thin',
    itemLevelClass: { fontWeight: '300 !important' },
  },
  {
    text: 'Regular',
    value: 'regular',
    itemLevelClass: { fontWeight: '400 !important' },
  },
  {
    text: 'Medium',
    value: 'medium',
    itemLevelClass: { fontWeight: '500 !important' },
  },
  {
    text: 'Bold',
    value: 'bold',
    itemLevelClass: { fontWeight: '700 !important' },
  },
];

const HOVER_EFFECT_DROPDOWN = [
  {
    text: 'Zoom in',
    value: 'zoomIn',
  },
  {
    text: 'Ambient',
    value: 'ambient',
  },
];

// Complete config

export const FeatureListPresentationConfig = {
  childrenBehaviourConfig: {
    builder: [
      {
        forType: 'layout',
        element: 'checkbox',
        componentLabel: 'On click, Open item in new tab',
        accessKey: ['openInNewTab'],
      },
    ],
  },
  sectionContent: {
    sectionHeading: 'Section content',
    description: '',
    tooltipContent: '',
    builder: [
      {
        forType: 'layout',
        componentLabel: 'Heading',
        element: 'inputEditor',
        accessKey: ['heading'],
        charCountKey: 'headingCharCount',
        placeholder: 'Enter title',
        maxLength: 100,
      },
      {
        forType: 'layout',
        componentLabel: 'Description',
        element: 'inputEditor',
        placeholder: 'Enter description',
        charCountKey: 'descriptionCharCount',
        accessKey: ['description'],
        textColor: '#808080',
        maxLength: 100,
      },
      {
        forType: 'layout',
        componentLabel: 'Description position',
        element: 'multiselect',
        accessKey: ['descriptionPosition'],
        componentData: DESCRIPTION_POSITION,
      },
      {
        forType: 'layout',
        componentLabel: 'Heading alignment',
        element: 'multiselect',
        accessKey: ['textAlign'],
        componentData: POSITIONS_DATA,
      },
      {
        forType: 'layout',
        componentLabel: 'Section colour',
        editLabel: 'Edit colour',
        uniqueKey: 'selectionColors',
        element: 'colorsCombination',
        accessKey: ['colors'],
      },
    ],
  },
  layoutAndDesign: {
    sectionHeading: 'Layout & design',
    description: '',
    tooltipContent: '',
    builder: [
      {
        forType: 'layout',
        componentLabel: 'Layout style',
        element: 'multiselect',
        accessKey: ['layoutType'],
        componentData: LAYOUT_STYLE,
      },
      {
        forType: 'layout',
        componentLabel: 'Number of columns - Desktop',
        element: 'slider',
        accessKey: ['numColumnsDesktop'],
        min: 2,
        max: 6,
        suffix: '',
        step: 1,
      },
      {
        forType: 'layout',
        componentLabel: 'Number of columns - Mobile',
        element: 'slider',
        accessKey: ['numColumnsMobile'],
        min: 1,
        max: 2,
        suffix: '',
        step: 1,
      },
    ],
  },
  carouselSettings: {
    sectionHeading: 'Carousel settings',
    description: '',
    tooltipContent: '',
    isVisible: (layout) =>
      layout.layoutName === 'layout2' || layout.layoutName === 'layout4',
    builder: [
      {
        forType: 'layout',
        element: 'dropdown',
        componentLabel: 'Progress indicator',
        accessKey: ['progressIndicator'],
        dropdownData: PROGRESS_INDICATOR_DROPDOWN,
        customChildRender: false,
        placeholder: '',
      },
      {
        forType: 'layout',
        element: 'dropdown',
        componentLabel: 'Arrow shape',
        accessKey: ['arrowShape'],
        dropdownData: ARROW_SHAPE_DROPDOWN,
        customChildRender: true,
        placeholder: '',
      },
      {
        forType: 'layout',
        element: 'colorDetails',
        componentLabel: 'Arrow colour',
        colorsData: [
          {
            text: 'arrowColor',
            displayVal: 'Arrow colour',
            accessKey: ['arrowColor'],
            shouldUpdateData: false,
            data: [],
          },
          {
            text: 'shapeColor',
            displayVal: 'Shape colour',
            accessKey: ['shapeColor'],
            shouldUpdateData: false,
            data: [],
          },
        ],
      },
      {
        forType: 'layout',
        element: 'colorDetails',
        componentLabel: 'Progress indicator color',
        colorsData: [
          {
            text: 'progressIndicatorColor',
            displayVal: 'Colour',
            accessKey: ['progressIndicatorColor'],
            shouldUpdateData: false,
            data: [],
          },
        ],
      },
      {
        forType: 'layout',
        element: 'multiselect',
        componentLabel: 'Arrow position',
        accessKey: ['arrowPosition'],
        dynamicData: true,
        getDynamicData: (layout, onChange, activeWidgetProps) => {
          if (layout?.textAlign === 'left') {
            return ARROW_POSITION;
          } else {
            if (layout?.textAlign === 'left') {
              layout.textAlign = 'center';
              onChange(activeWidgetProps);
            }
            if (layout?.textAlign === 'center' && layout?.arrowPosition === 'top') {
              layout.arrowPosition = 'center';
              onChange(activeWidgetProps);
            }
            return ARROW_POSITION?.slice(1, 3);
          }
        },
        componentData: ARROW_POSITION,
      },
      {
        forType: 'layout',
        element: 'checkbox',
        componentLabel: 'Show arrows in mobile',
        accessKey: ['showArrowInMobile'],
      },
    ],
  },
  viewAllButton: {
    builder: [
      {
        forType: 'layout',
        element: 'button',
        buttonHeading: 'View all',
        subHeading:
          'This button will be visible when all items are not in display at front.',
        showColorCombination: true,
        showDeleteIcon: false,
        showRedirectionField: false,
        accessKey: ['viewAllItemsButtonConfig'],
      },
    ],
  },
  collectionName: {
    sectionHeading: 'Item name',
    description: '',
    tooltipContent: '',
    isShow: true,
    builder: [
      {
        forType: 'layoutItem',
        element: 'dropdown',
        componentLabel: 'Position',
        accessKey: ['contentConfig', 'textConfig', 'yAlignment'],
        dynamicData: true,
        dynamicDataList: (layout) => {
          if (layout.layoutName === 'layout3' || layout.layoutName === 'layout4') {
            return TEXT_POSITION_DROPDOWN?.slice(0, TEXT_POSITION_DROPDOWN?.length - 1);
          } else {
            return TEXT_POSITION_DROPDOWN;
          }
        },
        customChildRender: false,
        placeholder: '',
      },
      {
        forType: 'layoutItem',
        componentLabel: 'Text alignment',
        element: 'multiselect',
        accessKey: ['contentConfig', 'textConfig', 'xAlignment'],
        componentData: CARD_TEXT_ALIGNMENT,
      },
      {
        forType: 'layoutItem',
        element: 'dropdown',
        componentLabel: 'Text thickness',
        accessKey: ['contentConfig', 'textConfig', 'textThicness'],
        dropdownData: TEXT_THICKNESS,
        customChildRender: false,
        placeholder: '',
      },
      {
        forType: 'layoutItem',
        element: 'dropdown',
        componentLabel: 'Text size',
        accessKey: ['contentConfig', 'textConfig', 'textSize'],
        dropdownData: TEXT_SIZE_DROPDOWN,
        customChildRender: false,
        placeholder: '',
      },
      {
        forType: 'layoutItem',
        componentLabel: 'Text case',
        element: 'multiselect',
        accessKey: ['contentConfig', 'textConfig', 'textCase'],
        componentData: TEXT_CASE,
      },
      {
        forType: 'layoutItem',
        element: 'colorDetails',
        conditionalRender: true,
        isVisible: (layout) =>
          layout.layoutName === 'layout3' || layout.layoutName === 'layout4',
        componentLabel: '',
        colorsData: [
          {
            text: 'textColor',
            displayVal: 'Name colour',
            accessKey: [
              'contentConfig',
              'textConfig',
              'appearanceButton',
              'btnStyleConfig',
              'textColor',
            ],
            shouldUpdateData: false,
            data: [],
          },
        ],
      },
      {
        forType: 'layoutItem',
        componentLabel: 'Improve text readability',
        componentSubLabel: 'Image overlay',
        element: 'slider',
        accessKey: ['contentConfig', 'textConfig', 'overlayOpacity'],
        min: 1,
        max: 100,
        suffix: '%',
        step: 1,
      },
    ],
  },
  collectionNameButton: {
    sectionHeading: 'Collection name',
    description: '',
    tooltipContent: '',
    isVisible: (layout) =>
      layout.layoutName === 'layout1' || layout.layoutName === 'layout2',
    builder: [
      {
        forType: 'layoutItem',
        element: 'button',
        buttonHeading: 'Collection name appearance',
        showColorCombination: true,
        showDeleteIcon: false,
        showRedirectionField: false,
        showButtonNameField: false,
        showOnOffToggle: false,
        buttonStyleName: 'Appearance',
        hoverAnimationName: 'Name hover animation',
        buttonColorName: 'Name colour',
        accessKey: ['contentConfig', 'textConfig', 'appearanceButton'],
      },
    ],
  },
  collectionButton: {
    isVisible: (layout) =>
      layout.layoutName === 'layout3' || layout.layoutName === 'layout4',
    builder: [
      {
        forType: 'layoutItem',
        element: 'button',
        showColorCombination: true,
        showDeleteIcon: false,
        showRedirectionField: false,
        showOnOffToggle: false,
        accessKey: ['contentConfig', 'buttonConfig'],
      },
    ],
  },
  hoverEffect: {
    sectionHeading: 'Hover effect',
    builder: [
      {
        forType: 'layoutItem',
        element: 'dropdown',
        componentLabel: 'Card hover effect',
        accessKey: ['hoverEffect'],
        dropdownData: HOVER_EFFECT_DROPDOWN,
        customChildRender: false,
        placeholder: '',
      },
    ],
  },
  sectionSetting: {
    sectionHeading: 'Section settings',
    builder: [
      {
        forType: 'layout',
        componentLabel: 'Desktop padding top and bottom',
        element: 'slider',
        accessKey: ['paddingY'],
        min: 0,
        max: 150,
        suffix: 'px',
        step: 1,
      },
      {
        forType: 'layout',
        componentLabel: 'Mobile padding top and bottom',
        element: 'slider',
        accessKey: ['mobilePaddingY'],
        min: 0,
        max: 100,
        suffix: 'px',
        step: 1,
      },
      {
        forType: 'layout',
        componentLabel: 'Margin top',
        element: 'slider',
        accessKey: ['marginTop'],
        min: 0,
        max: 100,
        suffix: 'px',
        step: 1,
      },
      {
        forType: 'layout',
        componentLabel: 'Margin bottom',
        element: 'slider',
        accessKey: ['marginBottom'],
        min: 0,
        max: 100,
        suffix: 'px',
        step: 1,
      },
    ],
  },
};

export const LAYOUT_TYPE_HEADING = {
  layout1: 'Layout 1',
  layout2: 'Layout 2',
  layout3: 'Layout 3',
  layout4: 'Layout 4',
};

export const CONTENT_POSITION_TYPE = {
  OVERLAY: 'overlay',
  BELOW: 'below',
};

export const LIST_TYPE = {
  COLLECTIONS: 'collections',
  CATEGORY: 'category',
};

export const LIST_ITEM_HEADING = {
  collection: 'Collections',
  category: 'Categories',
};

export const CARD_SHAPE = 'cardShape';
export const Y_ALIGNMENT = 'yAlignment';
export const IMAGE_OVERLAY_TOP = ['top', 'middle', 'bottom'];

export const DEFAULT_COLLECTION_CARD_IMAGE =
  'https://cdn.dotpe.in/longtail/themes/8073865/JXSaayl0.webp';
