import { getDefaultConfig } from 'src/components/WidgetMaker/utils/common';

// Default data for layout and cards
export const DEFAULT_INSTA_LIST_ARRANGEMENT_PRESENTATION = {
  layoutName: 'layout1',
  viewLabel: 'Instagram',
  widgetName: 'InstagramEmbed',
  layoutType: 'grid',
  heading: '<h2>Follow us on Instagram</h2>',
  headingCharCount: 0,
  instagramAccount: '',
  description: '',
  descriptionCharCount: 0,
  textAlign: 'left',
  colors: {
    selectedColorSchemeIndex: 0,
    colorSchemes: [
      {
        background: '#ffffff',
        text: '#000000',
        isDefault: true,
      },
      {
        background: '#000000',
        text: '#ffffff',
        isDefault: false,
      },
    ],
  },
  openInNewTab: false,
  contentItems: {
    isAllSelected: false,
    type: 'instagramPost',
    listItem: [],
  },
  numColumnsDesktop: 3,
  numColumnsMobile: 2,
  progressIndicator: 'none',
  arrowShape: 'circular',
  arrowColor: '#000000',
  shapeColor: '#ffffff',
  arrowPosition: 'top',
  progressIndicatorColor: '#000000',
  showArrowInMobile: true,
  mobilePaddingY: 40,
  paddingY: 40,
  marginTop: 0,
  marginBottom: 0,
  viewAllItemsButtonConfig: [
    getDefaultConfig('bannerButton', '#111C36', '#FFFFFF', 'See profile'),
  ],
};

export const DEFAULT_INSTA_CARD_FRAME_CONFIG = {
  cardShape: 'portraitShort',
  aspectRatio: '3:4',
  hoverEffect: 'zoomIn',
  contentConfig: {
    textConfig: {
      type: 'below',
      xAlignment: 'center',
      yAlignment: 'below',
      textThicness: 'regular',
      textSize: 'medium',
      textCase: 'none',
      overlayOpacity: 1,
      appearanceButton: [getDefaultConfig('collectionBtn', '#111C36', '#111C36')],
    },
    buttonConfig: [
      {
        ...getDefaultConfig('bannerButton', '#FFFFFF', '#111C36', 'View post'),
        isShow: false,
      },
    ],
  },
};

export const INSTA_LAYOUT_SELECTABLES = [
  {
    name: 'layout1',
    cdn: 'https://cdn.dotpe.in/longtail/themes/8073865/eVb88qUO.webp',
    // These are the base configs meapped with each layout
    configs: {
      layout: {
        layoutName: 'layout1',
      },
      card: {
        contentConfig: {
          buttonConfig: [{ isShow: false }],
        },
      },
    },
  },
  {
    name: 'layout2',
    cdn: 'https://cdn.dotpe.in/longtail/themes/8073865/VIpuX8Me.webp',
    // These are the base configs mapped with each layout
    configs: {
      layout: {
        layoutName: 'layout2',
      },
      card: {
        contentConfig: {
          buttonConfig: [{ isShow: false }],
        },
      },
    },
  },
  {
    name: 'layout3',
    cdn: 'https://cdn.dotpe.in/longtail/themes/8073865/PyKLoA0B.webp',
    // These are the base configs mapped with each layout
    configs: {
      layout: {
        layoutName: 'layout3',
      },
      card: {
        contentConfig: {
          textConfig: {
            appearanceButton: [
              {
                type: 'textual',
                buttonAnimationConfig: { classKey: '', animationName: 'None' },
              },
            ],
          },
          buttonConfig: [{ isShow: true }],
        },
      },
    },
  },
];

export const LAYOUT_STYLE = [
  {
    text: 'Grid',
    value: 'grid',
  },
  {
    text: 'Carousel',
    value: 'carousel',
  },
];

export const InstagramEmbedConfig = {
  childrenBehaviourConfig: {
    sectionHeading: 'Account',
    description: '',
    builder: [
      {
        forType: 'layout',
        componentLabel: 'Connect your account to manage Instagram for your online store.',
        element: 'redirection',
        accessKey: ['instagramAccount'],
      },
    ],
  },
  layoutAndDesign: {
    sectionHeading: 'Layout & design',
    description: '',
    tooltipContent: '',
    builder: [
      {
        forType: 'layout',
        element: 'layoutComponent',
        componentLabel: 'Desktop Layout',
        accessKey: ['id'],
      },
      {
        forType: 'layout',
        element: 'multiselect',
        componentLabel: 'Layout style',
        accessKey: ['layoutType'],
        dynamicData: false,
        componentData: LAYOUT_STYLE,
      },
      {
        forType: 'layout',
        componentLabel: 'Number of columns - Desktop',
        element: 'slider',
        accessKey: ['numColumnsDesktop'],
        min: 2,
        max: 8,
        suffix: '',
        step: 1,
      },
      {
        forType: 'layout',
        componentLabel: 'Number of columns - Mobile',
        element: 'slider',
        accessKey: ['numColumnsMobile'],
        min: 1,
        max: 2,
        suffix: '',
        step: 1,
      },
    ],
  },
  sectionContent: {
    sectionHeading: 'Section content',
    description: '',
    tooltipContent: '',
    builder: [
      {
        forType: 'layout',
        componentLabel: 'Heading',
        element: 'inputEditor',
        accessKey: ['heading'],
        charCountKey: 'headingCharCount',
        placeholder: 'Enter title',
        maxLength: 100,
      },
      {
        forType: 'layout',
        componentLabel: 'Description',
        element: 'inputEditor',
        placeholder: 'Enter description',
        charCountKey: 'descriptionCharCount',
        accessKey: ['description'],
        textColor: '#808080',
        maxLength: 100,
      },
      {
        forType: 'layout',
        componentLabel: 'Section colour',
        editLabel: 'Edit colour',
        uniqueKey: 'selectionColors',
        element: 'colorsCombination',
        accessKey: ['colors'],
      },
    ],
  },
  viewAllButton: {
    isVisible: (layout) => true,
    // layout.layoutName === 'layout1' || layout.layoutName === 'layout3',
    builder: [
      {
        forType: 'layout',
        element: 'button',
        buttonHeading: 'Button',
        subHeading: '',
        showColorCombination: true,
        showDeleteIcon: false,
        showRedirectionField: false,
        accessKey: ['viewAllItemsButtonConfig'],
      },
    ],
  },
};

export const LAYOUT_TYPE_HEADING = {
  layout1: 'Layout 1',
  layout2: 'Layout 2',
  layout3: 'Layout 3',
};

export const CONTENT_POSITION_TYPE = {
  OVERLAY: 'overlay',
  BELOW: 'below',
};

export const LIST_TYPE = {
  COLLECTIONS: 'collections',
  CATEGORY: 'category',
};

export const LIST_ITEM_HEADING = {
  collection: 'Collections',
  category: 'Categories',
};
