export const WIDGET_DND_ACTION_TYPES = {
  UPDATE_WIDGETS: 'widgetDndContext/updateWidgets',
  SET_ACTIVE_WIDGET: 'widgetDndContext/setActiveWidgets',
  PAGE_UPDATE_STATUS: 'widgetDndContext/updatePageUpdateStatus',
  UPDATE_SIDE_PANEL_STATE: 'widgetDndContext/updateSidePanelState',
  ADD_WIDGET_INDEX: 'widgetDndContext/updateAddWidgetIndex',
  SET_ACTIVE_HEADER_DATA: 'widgetDndContext/setActiveHeaderData',
  SET_ACTIVE_FOOTER_DATA: 'widgetDndContext/setActiveFooterData',
  SET_HEADER_FOOTER_PROPERTY_ACTIVE_STATUS:
    'widgetDndContext/setHeaderFooterPropertyActiveStatus',
  SET_WIDGET_DELETE_MODAL_STATUS: 'widgetDndContext/setWidgetDeleteModalStatus',
  SET_WIDGET_ID_TO_DELETE: 'widgetDndContext/setWidgetIDToDelete',
  SET_SHOW_PREVIEW: 'widgetDndContext/setShowPreview',
  TOGGLE_PREVIEW_DEVICE: 'widgetDndContext/setPreviewDevice',
  SET_POST_PUBLISH_MODAL_STATUS: 'widgetDndContext/setPostPublishModalStatus',
  SET_ACTIVE_ITEM: 'widgetDndContext/setActiveItem',
  SET_WIDGET_SETTINGS_SECTION_STATE: 'widgetDndContext/widgetSettingsSectionState',
  SET_GLOBAL_SETTING: 'widgetDndContext/setGlobalSetting',
  SET_GLOBAL_STYLE: 'widgetDndContent/setGlobalStyle',
  FOOTER_WIDGET_SECTION: 'widgetDndContent/footerWidgetSection',
  SET_BUILDER_FLAG: 'widgetDndContent/setBuilderFlag',
  SET_CUSTOM_TAG: 'set/custom/tag',
  SET_SNACKBAR_CONFIG: 'setConfig/Snackbar',
  SET_VARIANT_DATA: 'set/variant/data',
  SET_REELS_OUTLINE_DATA: 'set/reels/outline/data',
  SET_SELECTED_WIDGET_SESSION_STATE: 'widgetDndContext/selectedWidgetSessionState',
};
