import { StoreTheme } from '@/redux/reducers/store';
import { FEATURE_LOCKS_INITIAL_LIST, INVALID_DOMAIN_NAMES } from '@/utils/constants';
import axios from 'axios';
import { ENDPOINT } from '../config/endpoints';
import apiCall from '../services/api';
import { fetchDomainFromHeaders } from 'src/utils/fetchDomainFromHeaders';
import { isWebsiteInternallyRouted } from 'src/utils/isWebsiteInternallyRouted';
import { NextPageContext } from 'next';

function checkForInvalidDomainName(storename) {
  if (
    INVALID_DOMAIN_NAMES.includes(storename) ||
    storename.includes(INVALID_DOMAIN_NAMES[2])
  )
    return true;
  return false;
}

interface IFetchStoreInfo {
  domain?: string;
  isCustomDomain?: boolean;
  forBuilder?: boolean;
  ctx?: NextPageContext;
}
/**
 * Function for getting store Info by subdomain
 */
export async function fetchStoreInfo(param: IFetchStoreInfo) {
  const {
    ctx = null,
    forBuilder = false,
    // These are used if we want to pass our own configs
    domain: domainFromArgs = '',
    isCustomDomain: isCustomDomainFromArgs,
  } = param;

  const { domain: domainFromCtx, isCustomDomain: isCustomDomainFromCtx } =
    fetchDomainFromHeaders(ctx);

  const domainName = domainFromArgs || domainFromCtx;
  const isCustomDomain = domainFromArgs ? isCustomDomainFromArgs : isCustomDomainFromCtx;

  if (checkForInvalidDomainName(domainName)) {
    return { error: 'Invalid Domain Names' };
  }

  const callBuilderStore = forBuilder && ctx?.query?.token;

  try {
    const domain = isCustomDomain ? domainName : 'd-' + domainName;
    const headers = {
      'Content-Type': 'application/json',
      secret: '938923c72331801ab55687160a048d5b',
      app_os: 'cfe',
      domain:
        ctx && !isWebsiteInternallyRouted(ctx?.req?.headers?.host)
          ? `${ctx?.req?.headers?.host}${(ctx as any)?.resolvedUrl}`
          : ``,
    };

    if (callBuilderStore) {
      headers['auth_token'] = ctx?.query?.token;
    }

    const response = await axios.get(
      !callBuilderStore
        ? ENDPOINT.STORE.getStoreBySubDomain(domain)
        : ENDPOINT.STORE.getStoreBySubDomainWithToken(domain),
      {
        headers,
      }
    );

    const data = response.data as any; // { store: StoreDetails };
    return { error: null, data };
  } catch (error) {
    console.log('Error in getStoreBySubDomain:  ', error);
    return { error };
  }
}

/**
 * Function for getting theme object
 * by theme ID
 * @param {number} id
 * @param {number} storeId
 * @return {object}
 */
export async function getThemeById(id, storeId) {
  try {
    const response = await axios.get(ENDPOINT.STORE.getThemeById(id, storeId), {
      headers: {
        app_os: 'cfe',
      },
    });
    return { error: null, data: response.data as { status: number; theme: StoreTheme } };
  } catch (error) {
    return { error };
  }
}
/**
 * this function fetches dummy categories and associated products to show in theme preview
 * @param tagId
 * @returns {object}
 */
export async function getDummyProductsByThemeTagId(tagId) {
  try {
    const response = await axios.get(ENDPOINT.STORE.getDummyProductsByThemeTagId(tagId), {
      headers: {
        app_os: 'cfe',
      },
    });

    return { error: null, data: response?.data };
  } catch (error) {
    return { error };
  }
}

export async function getFeatureLocks(storeId) {
  const returnResponse = {
    error: null,
    data: FEATURE_LOCKS_INITIAL_LIST,
  };
  try {
    if (!storeId) {
      returnResponse.error = 'Invalid store id';
      return returnResponse;
    }
    const response = await axios.get(ENDPOINT.STORE.getFeatureLocks(storeId), {
      headers: {
        app_os: 'cfe',
      },
    });
    if (response?.data?.status) returnResponse.data = JSON.parse(response?.data?.data);
    return returnResponse;
  } catch (error) {
    returnResponse.error = error;
    return returnResponse;
  }
}

export async function fetchAdvSeoDetails(typeDetails) {
  const { storeId, type, typeId } = typeDetails;
  const value = parseInt(typeId);
  try {
    if (storeId && type && typeId && Number.isInteger(value) && typeId != undefined) {
      const response = await axios.get(
        ENDPOINT.SEO.getAdvanceCustomSeoDetails(storeId, type, typeId),
        {
          headers: {
            app_os: 'cfe',
          },
        }
      );
      return { error: null, data: response?.data };
    } else {
      return { error: 'Invalid Input', data: null };
    }
  } catch (error) {
    return { error };
  }
}

export async function fetchJsonPageWidgetResponse(jsonPageWidgetData) {
  let widgetData = {};

  const jsonPageWidgetId = jsonPageWidgetData?.widget_id;

  if (!jsonPageWidgetId) {
    return {
      error: true,
      data: {},
    };
  }

  try {
    const response = await axios.get(
      ENDPOINT.ADDITIONAL_PAGES.getStaticWidgetDetails(jsonPageWidgetId),
      {
        headers: {
          app_os: 'cfe',
        },
      }
    );

    const data = JSON.parse(response?.data?.data);
    const parsedTemplateData = JSON.parse(data?.template);
    widgetData = parsedTemplateData;

    return {
      error: false,
      data: widgetData,
    };
  } catch (error) {
    return {
      error: true,
      data: {},
    };
  }
}

export async function fetchJsonPageWidgetDraftResponse(storeInfo) {
  let widgetData = {};

  if (!storeInfo.store_id) {
    return {
      error: true,
      data: {} as any,
    };
  }

  try {
    const response = await axios.get(
      ENDPOINT.ADDITIONAL_PAGES.getDraftWidgets(storeInfo.store_id),
      {
        headers: {
          app_os: 'cfe',
        },
      }
    );

    widgetData = JSON.parse(response.data.data) || ({} as any);

    return {
      error: false,
      data: widgetData,
    };
  } catch (error) {
    console.log('Error in get draft api:   ', error);
    return {
      error: true,
      data: {},
    };
  }
}

export async function getCouponList(storeId: number, userId = 0) {
  try {
    const pageResponse = await apiCall({
      method: 'GET',
      url: ENDPOINT.PROMO.getAllPromos(storeId, userId),
    });

    if (pageResponse && pageResponse.data.status) {
      return JSON.parse(pageResponse.data.data);
    }
  } catch (error) {
    return { error };
  }
}
