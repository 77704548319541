import { getDefaultConfig } from 'src/components/WidgetMaker/utils/common';
import {
  CARD_APPEARANCE_DATA,
  DESCRIPTION_POSITION,
  HEADING_ALIGNMENT,
  HOVER_EFFECT_DROPDOWN,
  TEXT_SIZE_DROPDOWN,
} from '../MultiColumn/MultiColumnStaticValues';
import { LAYOUT_STYLE } from '../LogoSlider/LogoSliderConfig';
import { CARD_SHAPE_DROPDOWN } from '../MultiColumn/MultiColumnConfig';
import { DATE_POSITION, MOBILE_POSITION, TEXT_POSITION } from './BlogConfigStatic';
import { TEXT_CASE, TEXT_THICKNESS } from '../ListPresentation/ListPresentationConfig';
import { IMAGE_WIDTH_DATA } from '../../GlobalStyle/globalSettingConstant';

export const DEFAULT_BLOG_LAYOUT = {
  layoutName: 'layout1',
  type: 'blog',
  heading: '<h2>Our blog<h2>',
  headingCharCount: 0,
  description: '<p><p>',
  descriptionCharCount: 0,
  descriptionPosition: 'below',
  textAlign: 'left',
  colors: {
    selectedColorSchemeIndex: 0,
    colorSchemes: [
      {
        background: '#ffffff',
        text: '#000000',
        isDefault: true,
      },
      {
        background: '#000000',
        text: '#ffffff',
        isDefault: false,
      },
    ],
  },
  numColumnsDesktop: 4,
  numColumnsMobile: 1,
  mobileImagePosition: 'top',
  appearance: 'standard',
  imageWidth: 'fullWidth',
  backgroundColor: '#FFFFFF',
  borderColor: '#E2E4E7',
  mobilePaddingY: 40,
  paddingY: 70,
  marginTop: 0,
  marginBottom: 0,
  layoutType: 'carousel',
  viewAllItemsButtonConfig: [
    getDefaultConfig(
      'productCardButton',
      '#111C36',
      '#111C36',
      'View all',
      'outline',
      'widgetBtnFillRightWrapper',
      'Swipe to fill',
      true
    ),
  ],
};

export const DEFAULT_BLOG_CARD = {
  imageShape: 'landscape',
  isShow: true,
  aspectRatio: '3:2',
  hoverType: 'zoomIn',
  showDate: true,
  showDescription: true,
  textPosition: 'below',
  textAlignment: 'left',
  datePosition: 'top',
  dateSize: 'extraSmall',
  titleSizeDesktop: 'extraLarge',
  titleSizeMobile: 'large',
  descriptionSizeDesktop: 'small',
  descriptionSizeMobile: 'small',
  titleTextCase: 'none',
  descriptionTextCase: 'none',
  dateTextCase: 'none',
  titleColor: '#111C36',
  descriptionColor: '#555D70',
  dateColor: '#555D70',
  titleThicness: 'medium',
  descriptionThicness: 'regular',
  dateThicness: 'regular',
  buttonConfig: [
    getDefaultConfig(
      'productCardButton',
      '#FFFFFF',
      '#111C36',
      'Read more',
      'textual',
      'widgetBtnUnderlineRightWrapper2',
      'Underline right',
      false
    ),
  ],
};

export const BLOG_CONFIG = {
  sectionContent: {
    sectionHeading: 'Section content',
    description: '',
    tooltipContent: '',
    builder: [
      {
        forType: 'layout',
        componentLabel: 'Heading',
        element: 'inputEditor',
        accessKey: ['heading'],
        charCountKey: 'headingCharCount',
        placeholder: 'Enter title',
        maxLength: 100,
      },
      {
        forType: 'layout',
        componentLabel: 'Description',
        element: 'inputEditor',
        placeholder: 'Enter description',
        charCountKey: 'descriptionCharCount',
        accessKey: ['description'],
        textColor: '#808080',
        maxLength: 100,
      },
      {
        forType: 'layout',
        componentLabel: 'Description position',
        element: 'multiselect',
        accessKey: ['descriptionPosition'],
        componentData: DESCRIPTION_POSITION,
      },
      {
        forType: 'layout',
        componentLabel: 'Heading alignment',
        element: 'multiselect',
        accessKey: ['textAlign'],
        componentData: HEADING_ALIGNMENT,
      },
      {
        forType: 'layout',
        componentLabel: 'Section colour',
        editLabel: 'Edit colour',
        uniqueKey: 'selectionColors',
        element: 'colorsCombination',
        accessKey: ['colors'],
      },
    ],
  },
  layoutColumns: {
    sectionHeading: 'Layout',
    description: '',
    tooltipContent: '',
    builder: [
      {
        element: 'blogLayout',
      },
      {
        forType: 'layout',
        componentLabel: 'Mobile image position',
        element: 'multiselect',
        accessKey: ['mobileImagePosition'],
        componentData: MOBILE_POSITION,
        renderConditional: true,
        isVisible: (data, listData, card) => listData?.layoutName === 'layout1',
      },
      {
        forType: 'layout',
        componentLabel: 'Number of columns desktop',
        element: 'slider',
        accessKey: ['numColumnsDesktop'],
        min: 2,
        max: 5,
        suffix: '',
        step: 1,
      },
      {
        forType: 'layout',
        componentLabel: 'Number of columns mobile',
        element: 'slider',
        showToolTip: true,
        toolTipDetails: (list, card) => list?.mobileImagePosition === 'left',
        tooltipContent:
          'Only one column can be shown in mobile when image position is left.',
        spirteDetail: 'wb-sprite ic-info-grey',
        accessKey: ['numColumnsMobile'],
        min: 1,
        max: 2,
        suffix: '',
        step: 1,
      },
      {
        forType: 'layout',
        componentLabel: 'Layout style',
        element: 'multiselect',
        accessKey: ['layoutType'],
        componentData: LAYOUT_STYLE,
      },
      {
        forType: 'layout',
        componentLabel: 'Appearance',
        element: 'multiselect',
        accessKey: ['appearance'],
        componentData: CARD_APPEARANCE_DATA,
        renderConditional: true,
        isVisible: (data, listData, card) =>
          listData?.layoutName === 'layout1' && card?.textPosition === 'below',
      },
      {
        forType: 'layout',
        componentLabel: 'Image width',
        element: 'multiselect',
        renderConditional: true,
        isVisible: (data) => data?.appearance === 'card',
        accessKey: ['imageWidth'],
        componentData: IMAGE_WIDTH_DATA,
      },
      {
        element: 'colorDetails',
        componentLabel: 'Colours',
        forType: 'layout',
        conditionalRender: true,
        isVisible: (data) => data?.appearance === 'card',
        colorsData: [
          {
            forType: 'layout',
            text: 'backgroundColor',
            displayVal: 'Background colour',
            accessKey: ['backgroundColor'],
            shouldUpdateData: false,
            data: [],
          },
          {
            forType: 'layout',
            text: 'borderColor',
            displayVal: 'Border colour',
            accessKey: ['borderColor'],
            shouldUpdateData: false,
            data: [],
          },
        ],
      },
    ],
  },
  imageData: {
    sectionHeading: 'Image',
    description: '',
    tooltipContent: '',
    isShow: true,
    builder: [
      {
        forType: 'card',
        element: 'dropdown',
        componentLabel: 'Image shape',
        accessKey: ['imageShape'],
        dropdownData: CARD_SHAPE_DROPDOWN,
        customChildRender: true,
        placeholder: '',
      },
      {
        forType: 'card',
        element: 'dropdown',
        componentLabel: 'Image hover interaction',
        accessKey: ['hoverType'],
        dropdownData: HOVER_EFFECT_DROPDOWN,
        customChildRender: false,
        placeholder: '',
      },
    ],
  },
  textConfig: {
    sectionHeading: 'Text',
    description: '',
    tooltipContent: '',
    builder: [
      {
        forType: 'card',
        element: 'checkbox',
        componentLabel: 'Show date',
        accessKey: ['showDate'],
        customStyles: 'tw-items-center',
      },
      {
        forType: 'card',
        element: 'checkbox',
        componentLabel: 'Show description',
        accessKey: ['showDescription'],
        customStyles: 'tw-items-center',
      },
      {
        forType: 'card',
        componentLabel: 'Text position',
        element: 'multiselect',
        accessKey: ['textPosition'],
        componentData: TEXT_POSITION,
      },
      {
        forType: 'card',
        componentLabel: 'Text alignment',
        element: 'multiselect',
        accessKey: ['textAlignment'],
        componentData: HEADING_ALIGNMENT,
      },
      {
        forType: 'card',
        componentLabel: 'Date position',
        element: 'multiselect',
        accessKey: ['datePosition'],
        componentData: DATE_POSITION,
      },
      {
        forType: 'card',
        element: 'dropdown',
        componentLabel: 'Date size',
        accessKey: ['dateSize'],
        dropdownData: TEXT_SIZE_DROPDOWN,
        customChildRender: false,
        placeholder: '',
      },
      {
        forType: 'card',
        element: 'dropdown',
        componentLabel: 'Title size - Desktop',
        accessKey: ['titleSizeDesktop'],
        dropdownData: TEXT_SIZE_DROPDOWN,
        customChildRender: false,
        placeholder: '',
      },
      {
        forType: 'card',
        element: 'dropdown',
        componentLabel: 'Title size - Mobile',
        accessKey: ['titleSizeMobile'],
        dropdownData: TEXT_SIZE_DROPDOWN,
        customChildRender: false,
        placeholder: '',
      },
      {
        forType: 'card',
        element: 'dropdown',
        componentLabel: 'Description size - Desktop',
        accessKey: ['descriptionSizeDesktop'],
        dropdownData: TEXT_SIZE_DROPDOWN,
        customChildRender: false,
        placeholder: '',
      },
      {
        forType: 'card',
        element: 'dropdown',
        componentLabel: 'Description size - Mobile',
        accessKey: ['descriptionSizeMobile'],
        dropdownData: TEXT_SIZE_DROPDOWN,
        customChildRender: false,
        placeholder: '',
      },
      {
        componentLabel: 'Advanced settings',
        componentSubLabel: 'Customise Text casing, content thickness, colour.',
        element: 'customModal',
        modalHeading: 'Advance settings',
        componentData: [
          {
            forType: 'card',
            componentLabel: 'Title text case',
            element: 'multiselect',
            accessKey: ['titleTextCase'],
            componentData: TEXT_CASE,
          },
          {
            forType: 'card',
            componentLabel: 'Description text case',
            element: 'multiselect',
            accessKey: ['descriptionTextCase'],
            componentData: TEXT_CASE,
          },
          {
            forType: 'card',
            componentLabel: 'Date text case',
            element: 'multiselect',
            accessKey: ['dateTextCase'],
            componentData: TEXT_CASE,
          },
          {
            element: 'separator',
          },
          {
            element: 'colorDetails',
            componentLabel: 'Colours',
            forType: 'card',
            colorsData: [
              {
                forType: 'card',
                text: 'titleColor',
                displayVal: 'Title',
                accessKey: ['titleColor'],
                shouldUpdateData: false,
                data: [],
              },
              {
                forType: 'card',
                text: 'descriptionColor',
                displayVal: 'Description',
                accessKey: ['descriptionColor'],
                shouldUpdateData: false,
                data: [],
              },
              {
                forType: 'card',
                text: 'dateColor',
                displayVal: 'Date',
                accessKey: ['dateColor'],
                shouldUpdateData: false,
                data: [],
              },
            ],
          },
          {
            element: 'separator',
          },
          {
            forType: 'card',
            element: 'dropdown',
            componentLabel: 'Title thickness',
            accessKey: ['titleThicness'],
            dropdownData: TEXT_THICKNESS,
            customChildRender: false,
            placeholder: '',
          },
          {
            forType: 'card',
            element: 'dropdown',
            componentLabel: 'Description thickness',
            accessKey: ['descriptionThicness'],
            dropdownData: TEXT_THICKNESS,
            customChildRender: false,
            placeholder: '',
          },
          {
            forType: 'card',
            element: 'dropdown',
            componentLabel: 'Date thickness',
            accessKey: ['dateThicness'],
            dropdownData: TEXT_THICKNESS,
            customChildRender: false,
            placeholder: '',
          },
        ],
      },
    ],
  },
  multiColumnButton: {
    builder: [
      {
        forType: 'card',
        element: 'button',
        showColorCombination: true,
        keepSameColor: true,
        showRedirectionField: false,
        showRedirectionMsg: true,
        showDeleteIcon: false,
        accessKey: ['buttonConfig'],
      },
    ],
  },
  viewAllButton: {
    builder: [
      {
        forType: 'layout',
        element: 'button',
        dynamicHeading: true,
        getDynamicButtonHeading: () => 'View all blog button',
        showColorCombination: true,
        keepSameColor: true,
        showRedirectionField: false,
        showRedirectionMsg: true,
        showDeleteIcon: false,
        accessKey: ['viewAllItemsButtonConfig'],
      },
    ],
  },
  sectionSetting: {
    sectionHeading: 'Section settings',
    builder: [
      {
        forType: 'layout',
        componentLabel: 'Desktop padding top and bottom',
        element: 'slider',
        accessKey: ['paddingY'],
        min: 0,
        max: 150,
        suffix: 'px',
        step: 1,
      },
      {
        forType: 'layout',
        componentLabel: 'Mobile padding top and bottom',
        element: 'slider',
        accessKey: ['mobilePaddingY'],
        min: 0,
        max: 100,
        suffix: 'px',
        step: 1,
      },
      {
        forType: 'layout',
        componentLabel: 'Margin top',
        element: 'slider',
        accessKey: ['marginTop'],
        min: 0,
        max: 100,
        suffix: 'px',
        step: 1,
      },
      {
        forType: 'layout',
        componentLabel: 'Margin bottom',
        element: 'slider',
        accessKey: ['marginBottom'],
        min: 0,
        max: 100,
        suffix: 'px',
        step: 1,
      },
    ],
  },
};

export const BLOG_DEFAULT_VALUES = {
  layout1: {
    listPresentation: DEFAULT_BLOG_LAYOUT,
    cardFrame: DEFAULT_BLOG_CARD,
  },
  layout2: {
    listPresentation: {
      ...DEFAULT_BLOG_LAYOUT,
      layoutName: 'layout2',
      appearance: 'standard',
      mobileImagePosition: 'top',
    },
    cardFrame: {
      ...DEFAULT_BLOG_CARD,
      imageShape: 'portraitShort',
      aspectRatio: '3:4',
      textPosition: 'overlay',
      titleColor: '#FFFFFF',
      descriptionColor: '#FFFFFFCC',
      dateColor: '#FFFFFFCC',
    },
  },
};
