import { ADMIN_WIDGETS } from "src/constants/widgets";
import { ASPECT_RATIO_MAP, CARD_SHAPE_DROPDOWN } from "../ListPresentation/ListPresentationConfig";
import { IMAGE_DATA, IMAGE_TYPE } from "./constant";
import { DESCRIPTION_POSITION } from "../FeaturedListPresentation/FeaturedListPresentationConfig";
import { HEADING_ALIGNMENT, HOVER_EFFECT_DROPDOWN } from "../MultiColumn/MultiColumnStaticValues";
// import { LAYOUT_STYLE } from "../LogoSlider/LogoSliderConfig";

export const GALLERY_LAYOUT_SELECTABLE = [
  {
    name: 'layout1',
    heading: 'Layout 1',
    cdn: '/assets/images/widgetImages/Gallery/L1.png',
    list: {
      layoutName: 'layout1',
      layoutType: 'grid', 
      imageWidth: 'equal'
    },
    card: {
      imageShape: 'square',
      aspectRatio: ASPECT_RATIO_MAP.square
    },
  },
  {
    name: 'layout2',
    heading: 'Layout 2',
    cdn: '/assets/images/widgetImages/Gallery/L2.png',
    list: {
      layoutName: 'layout2',
      layoutType: 'carousel',
      imageWidth: 'equal'
    },
    card: {
    },
  },
  {
    name: 'layout3',
    heading: 'Layout 3',
    cdn: '/assets/images/widgetImages/Gallery/L3.png',
    list: {
      layoutName: 'layout3',
      layoutType: 'carousel',
    },
    card: {
      imageShape: 'portraitTall',
      aspectRatio: ASPECT_RATIO_MAP.portraitTall,
    },
  },
];

export const GALLERY_PRESENTATION_CONFIG = {
  layoutName: 'layout1',
  viewLabel: 'Gallery',
  widgetName: ADMIN_WIDGETS.GALLERY.type,
  layoutType: 'grid',
  heading: '<h2></h2>',
  descriptionPosition: 'below',
  textAlign: 'center',
  headingCharCount: 0,
  rowColumnGap: 20,
  instagramAccount: '',
  description: '',
  descriptionCharCount: 0,
  type: ADMIN_WIDGETS.GALLERY.type,
  colors: {
    selectedColorSchemeIndex: 0,
    colorSchemes: [
      {
        background: '#ffffff',
        text: '#000000',
        isDefault: true,
      },
      {
        background: '#000000',
        text: '#ffffff',
        isDefault: false,
      },
    ],
  },
  imageWidth: 'equal',
  backgroundColor: '#FFFFFF',
  borderColor: '#E2E4E7',
  numColumnsDesktop: 4,
  numColumnsMobile: 2,
  mobilePaddingY: 40,
  paddingY: 70,
  marginTop: 0,
  marginBottom: 0,
};

export const DEFAULT_GALLERY_CARD_CONFIG = {
  imageShape: 'square',
  aspectRatio: ASPECT_RATIO_MAP.square,
  hoverEffect: 'zoomIn',
  textAlign: 'left',
  hoverType: 'zoomIn',
  reelDesktopBehaviour: 'autoPlayAll',
  mobileEnlargeAndFocus: false,
  imagesData: [
    {
      ...IMAGE_DATA,
    },
    {
      ...IMAGE_DATA,
    },
    {
      ...IMAGE_DATA,
    },
    {
      ...IMAGE_DATA,
    },
  ],
};

export const GALLERY_CONFIG = {
  sectionContent: {
    sectionHeading: 'Section content',
    description: '',
    tooltipContent: '',
    builder: [
      {
        forType: 'layout',
        componentLabel: 'Heading',
        element: 'inputEditor',
        accessKey: ['heading'],
        charCountKey: 'headingCharCount',
        placeholder: 'Enter title',
        maxLength: 100,
      },
      {
        forType: 'layout',
        componentLabel: 'Description',
        element: 'inputEditor',
        placeholder: 'Enter description',
        charCountKey: 'descriptionCharCount',
        accessKey: ['description'],
        textColor: '#808080',
        maxLength: 100,
      },
      {
        forType: 'layout',
        componentLabel: 'Description position',
        element: 'multiselect',
        accessKey: ['descriptionPosition'],
        componentData: DESCRIPTION_POSITION,
      },
      {
        forType: 'layout',
        componentLabel: 'Heading alignment',
        element: 'multiselect',
        accessKey: ['textAlign'],
        componentData: HEADING_ALIGNMENT,
      },
      {
        forType: 'layout',
        componentLabel: 'Section colour',
        editLabel: 'Edit colour',
        uniqueKey: 'selectionColors',
        element: 'colorsCombination',
        accessKey: ['colors'],
      },
    ],
  },
  layoutAndDesign: {
    sectionHeading: 'Layout & design',
    description: '',
    tooltipContent: '',
    builder: [
      {
        componentLabel: 'Layout/design',
        componentData: GALLERY_LAYOUT_SELECTABLE,
        element: 'testimonialLayout',
      },
      {
        forType: 'layout',
        componentLabel: 'Number of columns - Desktop',
        element: 'slider',
        accessKey: ['numColumnsDesktop'],
        min: 4,
        max: 8,
        suffix: '',
        step: 1,
        renderConditional: true,
        isVisible: (listPresentation) => listPresentation?.layoutName !== 'layout3'
      },
      {
        forType: 'layout',
        componentLabel: 'Number of columns - Mobile',
        element: 'slider',
        accessKey: ['numColumnsMobile'],
        min: 1,
        max: 2,
        suffix: '',
        step: 1,
        renderConditional: true,
        isVisible: (listPresentation) => listPresentation?.layoutName !== 'layout3'
      },
      {
        forType: 'layout',
        componentLabel: 'Column gap/Image spacing',
        element: 'slider',
        accessKey: ['rowColumnGap'],
        min: 0,
        max: 24,
        suffix: 'px',
        step: 1,
        renderConditional: true,
        isVisible: (listPresentation) => (listPresentation?.layoutName !== 'layout3')
      },
    ],
  },
  image: {
    sectionHeading: 'Image',
    description: '',
    tooltipContent: '',
    builder: [
      {
        forType: 'layout',
        componentLabel: 'Image width',
        element: 'multiselect',
        accessKey: ['imageWidth'],
        componentData: IMAGE_TYPE,
        renderConditional: true,
        isVisible: (listPresentation) => (listPresentation?.layoutName !== 'layout3' && listPresentation?.layoutName !== 'layout1'),
        dynamicSubText: (activeWidgetProps) => {
          const { listPresentation } = activeWidgetProps;
          if(listPresentation?.imageWidth === IMAGE_TYPE[1].value) {
            return 'Number of columns may not work as expected since image width are unequal.'
          }
        }
      },
      {
        forType: 'image',
        element: 'dropdown',
        componentLabel: 'Image shape',
        accessKey: ['imageShape'],
        dropdownData: CARD_SHAPE_DROPDOWN,
        customChildRender: true,
        placeholder: '',
        renderConditional: true,
        isVisible: (listPresentation, cardFrameConfig) => {
          if(listPresentation?.layoutName === 'layout3' || listPresentation?.imageWidth === 'original')
          {
            cardFrameConfig.imageShape = 'landscape',
            cardFrameConfig.aspectRatio = ASPECT_RATIO_MAP.landscapeWide;
            return false;
          }
          return true
        }
      },
      {
        forType: 'card',
        element: 'dropdown',
        componentLabel: 'Image hover interaction',
        accessKey: ['hoverEffect'],
        dropdownData: HOVER_EFFECT_DROPDOWN,
        customChildRender: false,
        placeholder: '',
      },
    ]
  },
  sectionSetting: {
    sectionHeading: 'Section settings',
    builder: [
      {
        forType: 'layout',
        componentLabel: 'Desktop padding top and bottom',
        element: 'slider',
        accessKey: ['paddingY'],
        min: 0,
        max: 150,
        suffix: 'px',
        step: 1,
      },
      {
        forType: 'layout',
        componentLabel: 'Mobile padding top and bottom',
        element: 'slider',
        accessKey: ['mobilePaddingY'],
        min: 0,
        max: 100,
        suffix: 'px',
        step: 1,
      },
      {
        forType: 'layout',
        componentLabel: 'Margin top',
        element: 'slider',
        accessKey: ['marginTop'],
        min: 0,
        max: 100,
        suffix: 'px',
        step: 1,
      },
      {
        forType: 'layout',
        componentLabel: 'Margin bottom',
        element: 'slider',
        accessKey: ['marginBottom'],
        min: 0,
        max: 100,
        suffix: 'px',
        step: 1,
      },
    ],
  },
}